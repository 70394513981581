import { ITransactionType } from "common/types/transaction";
import { Api } from "../api";
import { ApiPaging, ApiResponse, paramApi } from "common/types/common";

export class TransactionTypeApi extends Api {
  async addTransactionType(data: ITransactionType, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "transaction-type/update" : "transaction-type/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<ITransactionType[]>>>(
      "post",
      "transaction-type/paging",
      data,
      null,
      undefined
    );
  }

  async getChoose(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<ITransactionType[]>>>(
      "post",
      "transaction-type/choose",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<ITransactionType>>(
      "get",
      `transaction-type/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<ITransactionType>>(
      "delete",
      `transaction-type/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
