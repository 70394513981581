import { Layout as AntLayout } from "antd";
import { Navbar, Sidebar } from "components/ui";
import React, { Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const contentStyle: React.CSSProperties = {
  padding: 20,
  // overflow: "scroll",
};

const layoutStyle: React.CSSProperties = {
  minHeight: "100vh",
};

const Layout = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) navigate("/login");

    if (location.pathname === "/") {
      navigate(`/applications/list`);
    }
  }, [auth, location]);

  return (
    <AntLayout style={layoutStyle}>
      <Navbar />
      <AntLayout>
        <Sidebar />
        <AntLayout.Content style={contentStyle}>
          <Suspense fallback={<>Loading...</>}>
            <Outlet />
          </Suspense>
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
