import { Form, FormProps, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../common/api";

interface ITransactionTypeSelect {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
  multiple?: boolean;
  extraParams?: { [key: string]: any };
  dependency?: any[];
  [key: string]: unknown;
}

const TransactionTypeSelect = ({
  label = "Tranzaksiya turi",
  placeholder = "Tranzaksiya turini tanlang",
  form,
  name,
  onSelect,
  multiple = false,
  extraParams = {},
  dependency = [],
  ...props
}: ITransactionTypeSelect) => {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };

  const transactionGetAll = async () => {
    try {
      setLoading(true);
      const response = await api.transactionType.getChoose({
        page: 1,
        limit: 200,
        ...extraParams,
      });
      setOptions(
        response.data.data.map((transactionType) => ({
          label: transactionType.name,
          value: transactionType._id,
        }))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    transactionGetAll();
  }, [...dependency]);

  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }} {...props}>
      <Select
        mode={multiple ? "multiple" : undefined}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        loading={loading}
      />
    </Form.Item>
  );
};

export default TransactionTypeSelect;
