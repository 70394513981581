import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { Button, Container, Filter, Table } from "components/ui";
import { useNavigate } from "react-router-dom";
import useCitiesContext from "../services/citiesContext";

const Cities = () => {
  const navigate = useNavigate();
  const {
    state: { filterFields, columns, data, loading },
    actions: {},
  } = useCitiesContext();

  return (
    <div>
      <Row>
        <Col xs={18} md={6}>
          <Button
            type="primary"
            style={{ backgroundColor: "dodgerblue" }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate("/management/cities/create")}
          >
            Shahar qo'shish
          </Button>
        </Col>
        <Col xs={6} md={18}>
          <Filter<any>
            filterFields={filterFields}
            onFinish={(values) => {
              console.log(values);
            }}
          />
        </Col>
      </Row>
      <Container padding={false} filter>
        <Table columns={columns} dataSource={data} loading={loading} />
      </Container>
    </div>
  );
};

export default Cities;
