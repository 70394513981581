import { IUsers } from "common/types/users";
import { ApplicationUserInfoContextProvider } from "../services/applicationUserInfoContext";
import ApplicationUserInfo from "./ApplicationUserInfo";

const index = (props: { data: IUsers | undefined }) => {
  return (
    <ApplicationUserInfoContextProvider>
      <ApplicationUserInfo {...props} />
    </ApplicationUserInfoContextProvider>
  );
};

export default index;
