import { Flex, Spin } from "antd";

const Loading = () => {
  return (
    <Flex
      justify="center"
      align="center"
      style={{ minHeight: "500px", height: "100%" }}
    >
      <Spin size="large" />
    </Flex>
  );
};

export default Loading;
