import { FormProps, message } from "antd";
import api from "common/api";
import { IUsers } from "common/types/users";
import moment from "moment";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export interface FieldType extends Omit<IUsers, "birth_date" | "valid_until"> {
  birth_date: Date;
  valid_until: Date;
}

const Context = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUsers>();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState({
    personal_info: false,
    documents: false,
  });
  const [editMode, setEditMode] = useState(false);

  const updateProfile = async (values: FieldType) => {
    try {
      setFormLoading((prev) => ({ ...prev, personal_info: true }));
      const response = await api.users.updatePersonalInfo({
        ...values,
        birth_date: moment(values.birth_date).toISOString(),
        valid_until: moment(values.valid_until).toISOString(),
        _id: user!._id,
      });
      setFormLoading((prev) => ({ ...prev, personal_info: false }));
      message.success(t("users.info.success_update"));
      setEditMode(false);
    } catch (err) {
      setFormLoading((prev) => ({ ...prev, personal_info: false }));
      console.log(err);
    }
  };

  const getUser = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.users.getById(id);
      setUser(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    updateProfile(values);
  };

  const deleteUser = async (id: string) => {
    try {
      const response = await api.users.delete(id);
      navigate("/users/list");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) getUser(id);
  }, []);

  return {
    state: { user, loading, editMode, formLoading },
    actions: { onFinish, setEditMode, deleteUser },
  };
};

const UsersInfoContext = createContext<any>({ state: {}, actions: {} });

export const UsersInfoContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UsersInfoContext.Provider value={value}>
      {children}
    </UsersInfoContext.Provider>
  );
};

export default function useUsersInfoContext() {
  return useContext<ReturnType<typeof Context>>(UsersInfoContext);
}
