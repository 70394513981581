import api from "common/api";
import { IApplication } from "common/types/applications";
import { IDocument } from "common/types/common";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const Context = ({ task }: { task: IApplication | undefined }) => {
  const [documents, setDocuments] = useState<IDocument[]>();
  const [loading, setLoading] = useState(true);
  const [userDocuments, setUserDocuments] = useState<
    {
      doc_id: string;
      user_id: string;
      value: string;
    }[]
  >();

  const getRequiredDocs = async (payload: {
    user_id: string;
    program_id: string;
  }) => {
    try {
      setLoading(true);
      const response = await api.application.getRequiredDocs(payload);
      setDocuments(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getUserDocs = async (id: string) => {
    try {
      const response = await api.application.getUserDocs(id);
      setUserDocuments(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (task?.user_id && task.program_id) {
      getRequiredDocs({ user_id: task.user_id, program_id: task.program_id });
      getUserDocs(task.user_id);
    }
  }, []);

  return {
    state: { documents, userDocuments, loading },
    actions: {},
  };
};

const DocumentsContext = createContext<any>({ state: {}, actions: {} });

export const DocumentsContextProvider: FC<{
  children: React.ReactNode;
  task: IApplication | undefined;
}> = ({ children, task }) => {
  const value = Context({ task });
  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  );
};

export default function useDocumentsContext() {
  return useContext<ReturnType<typeof Context>>(DocumentsContext);
}
