import {
  IApplication,
  IApplicationStatistics,
} from "common/types/applications";
import {
  ApiPaging,
  ApiResponse,
  IDocument,
  paramApi,
} from "common/types/common";
import { IUsers } from "common/types/users";
import { Api } from "../api";

export interface IAddApplication extends IUsers {
  program_id: string;
  user_id?: string;
}

export class ApplicationsApi extends Api {
  async addApplication(data: IAddApplication, id?: string) {
    return await this.execute<ApiResponse<IApplication>>(
      id ? "put" : "post",
      id ? "application/update" : "application/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async updatePosition(data: {
    _id: string;
    state_id: string;
    position: number;
  }) {
    return await this.execute<ApiResponse<any>>(
      "post",
      "application/update-position",
      data,
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IApplication[]>>>(
      "post",
      "application/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IApplication>>(
      "get",
      `application/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async getByUser(id: string) {
    return await this.execute<ApiResponse<IAddApplication>>(
      "get",
      `application/user/${id}`,
      null,
      null,
      undefined
    );
  }

  async getRequiredDocs(payload: { user_id: string; program_id: string }) {
    return await this.execute<ApiResponse<IDocument[]>>(
      "post",
      `user-docs/program`,
      payload,
      null,
      undefined
    );
  }

  async updateUserDoc(payload: {
    user_id: string;
    doc_id: string;
    value: string;
  }) {
    return await this.execute<ApiResponse<any>>(
      "put",
      `user-docs`,
      payload,
      null,
      undefined
    );
  }

  async getUserDocs(id: string) {
    return await this.execute<ApiResponse<any>>(
      "get",
      `user-docs/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<IApplication>>(
      "delete",
      `application/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }

  async getPayme(payload: {
    application_id: string;
    payment_type: "first_payment" | "second_payment";
    is_manual?: boolean;
  }) {
    return await this.execute<ApiResponse<any>>(
      "post",
      `application/pay`,
      payload,
      null,
      undefined
    );
  }

  async statistics(payload: paramApi) {
    return await this.execute<ApiResponse<IApplicationStatistics>>(
      "post",
      `application/statistics`,
      payload,
      null,
      undefined
    );
  }

  async setEmployee(payload: { _id: string; employee_id: string }) {
    return await this.execute<ApiResponse<IApplication>>(
      "post",
      `application/set-employee`,
      payload,
      null,
      undefined
    );
  }
}
