import {
  faAdd,
  faBackspace,
  faEdit,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { Button, Container } from "components/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import access_token from "storage/access_token";
import styled from "styled-components";
import useUniversitiesAddContext from "../services/universitiesAddContext";
import { Field } from "components/form";

const StyledFormListSpace = styled(Space)`
  .ant-space-item:nth-child(2) {
    flex-grow: 1;
  }
`;

const UniversitiesAdd = () => {
  const { id } = useParams();

  const {
    state: { form },
    actions: { onFinish, handleDelete },
  } = useUniversitiesAddContext();
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} md={19}>
          <Container padding={false} background={false}>
            <Space direction="vertical" size="large">
              <Container overflow={false} height={false}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={24} lg={4}>
                    <Field
                      form={form}
                      title="Logotip"
                      name="logo_img_url"
                      type="image"
                      required
                      // language={language}
                      // style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col xs={24} md={24} lg={20}>
                    <Field
                      form={form}
                      title="Universitetning asosiy rasmi"
                      name="main_img_url"
                      type="image"
                      required
                      // language={language}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Universitet nomi"
                      name="name"
                      type="input"
                      required
                      language={true}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Shahar"
                      name="city_id"
                      type="select-city"
                      required
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Turi"
                      name="type"
                      type="select"
                      options={[
                        {
                          label: "Ommaviy",
                          value: "public",
                        },
                        {
                          label: "Xususiy",
                          value: "private",
                        },
                      ]}
                      required
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      form={form}
                      title="Universitet haqida video"
                      name="video_url"
                      type="input"
                      required
                      language={true}
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      form={form}
                      title="Universitet haqida batafsil"
                      name="description"
                      type="textarea"
                      required
                      language={true}
                    />
                  </Col>
                </Row>
              </Container>
              <Container overflow={false} height={false}>
                <Form.List name="rankings">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <StyledFormListSpace
                          key={key}
                          align="baseline"
                          style={{ display: "flex" }}
                        >
                          <Form.Item {...restField} name={[name, "rank"]}>
                            <InputNumber />
                          </Form.Item>

                          <Form.Item {...restField} name={[name, "title"]}>
                            <Input />
                          </Form.Item>
                          <Button
                            type="primary"
                            icon={<FontAwesomeIcon icon={faXmark} />}
                            onClick={() => remove(name)}
                          />
                        </StyledFormListSpace>
                      ))}
                      <Button
                        icon={<FontAwesomeIcon icon={faAdd} />}
                        onClick={() => add()}
                        block
                      >
                        Qo'shish
                      </Button>
                    </>
                  )}
                </Form.List>
              </Container>
              <Container overflow={false} height={false}>
                <Typography.Title level={4}>Kirish</Typography.Title>
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Tashkil topgan yili"
                      name="established_year"
                      type="number-input"
                      required
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Studentlar soni"
                      name="student_count"
                      type="number-input"
                      required
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Xalqaro studentlar soni"
                      name="international_students"
                      type="number-input"
                      required
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Fakultetlar soni"
                      name="department_count"
                      type="number-input"
                      required
                    />
                  </Col>
                </Row>
              </Container>
              <Container overflow={false} height={false}>
                <Form.Item
                  name="gallery"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e?.fileList
                  }
                >
                  <Upload
                    name="file"
                    action={process.env.REACT_APP_UPLOAD_URL}
                    listType="picture-card"
                    // fileList={fileList}
                    // onChange={handleChange}
                    headers={{ Authorization: `Bearer ${access_token}` }}
                    accept="image/*"
                  >
                    Yuklash
                  </Upload>
                </Form.Item>
              </Container>
            </Space>
          </Container>
        </Col>
        <Col xs={24} md={5}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "#1EB53A" }}
              htmlType="submit"
            >
              Saqlash
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={id ? faTrash : faBackspace} />}
              block
              type={id ? "primary" : "default"}
              onClick={handleDelete}
            >
              {id ? "O'chirish" : "Bekor qilish"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default UniversitiesAdd;
