import { UniversitiesInfoContextProvider } from "../services/universitiesInfoContext";
import UniversitiesInfo from "./UniversitiesInfo";

const index = () => {
  return (
    <UniversitiesInfoContextProvider>
      <UniversitiesInfo />
    </UniversitiesInfoContextProvider>
  );
};

export default index;
