import { Drawer as AntDrawer, Form, InputNumber, Space } from "antd";
import {
  CashboxSelect,
  EmployeeSelect,
  PaymentMethodSelect,
  TransactionTypeSelect,
} from "components/form";
import { Button } from "components/ui";
import useSalariesContext from "../../services/salariesContext";

const Drawer = () => {
  const {
    state: { isOpen, form, loadingSubmit },
    actions: { handleCloseDrawer, onFinish },
  } = useSalariesContext();
  return (
    <AntDrawer
      title={"Oylik chiqarish"}
      onClose={handleCloseDrawer}
      open={isOpen}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Space direction="vertical" style={{ width: "100%" }}>
          <EmployeeSelect
            form={form}
            label="Xodim"
            name="employee_id"
            rules={[{ required: true }]}
          />
          <PaymentMethodSelect
            form={form}
            name="payment_method_id"
            rules={[{ required: true }]}
          />
          <CashboxSelect
            form={form}
            name="cashbox_id"
            rules={[{ required: true }]}
          />
          <Form.Item<any>
            name="amount"
            label="Miqdor"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={loadingSubmit}
          >
            Saqlash
          </Button>
        </Space>
      </Form>
    </AntDrawer>
  );
};

export default Drawer;
