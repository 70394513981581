import {
  Form,
  FormProps,
  Space,
  DatePicker as AntDatePicker,
  DatePickerProps,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";

interface IDatePicker {
  label?: string;
  range?: boolean;
  form?: FormProps["form"];
  name?: string;
  onChange?: (value: any) => void;
}

const StyledSpace = styled(Space)`
  display: flex;
  .ant-space-item:first-child {
    flex-grow: 1;
  }
`;

const DatePicker = ({
  label,
  range = false,
  form,
  name,
  onChange,
}: IDatePicker) => {
  let date = form?.getFieldValue(name);
  const handleChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (form && name) form?.setFieldValue(name, date ? date : undefined);
    if (onChange) onChange(date);
  };

  const handleRangeChange: RangePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    if (form && name) form?.setFieldValue(name, date ? date : undefined);
    if (onChange) onChange(date);
  };

  return (
    <>
      <StyledSpace>
        <Form.Item name={name} style={{ margin: 0 }} label={label}>
          {range ? (
            <AntDatePicker.RangePicker
              style={{ minWidth: "200px" }}
              onChange={handleRangeChange}
            />
          ) : (
            <AntDatePicker
              style={{ minWidth: "200px" }}
              onChange={handleChange}
            />
          )}
        </Form.Item>
      </StyledSpace>
    </>
  );
};

export default DatePicker;
