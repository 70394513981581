import { Form, FormProps, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../common/api";

interface IPaymentMethodSelectSelect {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
  multiple?: boolean;
  [key: string]: unknown;
}

const PaymentMethodSelect = ({
  label = "To'lov turi",
  placeholder = "To'lov turini tanlang",
  form,
  name,
  onSelect,
  multiple = false,
  ...props
}: IPaymentMethodSelectSelect) => {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };

  const transactionGetAll = async () => {
    try {
      setLoading(true);
      const response = await api.paymentMethod.getChoose({
        page: 1,
        limit: 200,
      });
      setOptions(
        response.data.data.map((paymentMethod) => ({
          label: paymentMethod.name,
          value: paymentMethod._id,
        }))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    transactionGetAll();
  }, []);

  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }} {...props}>
      <Select
        mode={multiple ? "multiple" : undefined}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        loading={loading}
      />
    </Form.Item>
  );
};

export default PaymentMethodSelect;
