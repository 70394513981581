import { ProgramsContextProvider } from "../services/programsContext";
import Programs from "./Programs";

const index = () => {
  return (
    <ProgramsContextProvider>
      <Programs />
    </ProgramsContextProvider>
  );
};

export default index;
