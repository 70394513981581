import { BranchesAddContextProvider } from "../services/branchesAddContext";
import BranchesAdd from "./BranchesAdd";

const index = () => {
  return (
    <BranchesAddContextProvider>
      <BranchesAdd />
    </BranchesAddContextProvider>
  );
};

export default index;
