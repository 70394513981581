import { IPrograms } from "common/types/programs";
import { ApiPaging, ApiResponse, ICity, paramApi } from "../../types/common";
import { Api } from "../api";

export class ProgramsApi extends Api {
  async addProgram(data: any, id?: string) {
    return await this.execute<ApiResponse<IPrograms>>(
      id ? "put" : "post",
      id ? "program/update" : "program/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IPrograms[]>>>(
      "post",
      "program/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IPrograms>>(
      "get",
      `program/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `program/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
