import { TableProps } from "antd";
import api from "common/api";
import { IUniversities } from "common/types/universities";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DataType {
  key: number;
  university: string;
  city: string;
  type: "public" | "private";
  program_count: number;
  applications_count: number;
  _id: string;
}

export interface IUniversitiesFilter {
  search: string;
  city: string[];
  type: string[];
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IUniversities[]>([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "city",
      label: "Shahar",
      name: "city",
    },
    {
      type: "select",
      label: "Turi",
      name: "type",
      options: [
        {
          label: "Ommaviy",
          value: "public",
        },
        {
          label: "Shaxsiy",
          value: "private",
        },
      ],
    },
    {
      type: "search",
      // label: "Turi",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Nomi",
        dataIndex: ["name", i18n.language],
        key: "name",
      },
      {
        title: "Shahar",
        dataIndex: ["city", "name", i18n.language],
        key: "city",
      },
      {
        title: "Turi",
        dataIndex: "type",
        key: "type",
        render: (type) => (type === "public" ? "Ommaviy" : "Shaxsiy"),
      },
      {
        title: "Dastur soni",
        dataIndex: "program_count",
        key: "program_count",
      },
      {
        title: "Arizalar soni",
        dataIndex: "applciation_count",
        key: "applciation_count",
      },
    ],
    [i18n.language]
  );

  const onRowClick = (record: DataType, rowIndex: number | undefined) => {
    navigate(`/universities/info/${record._id}`);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.university.getPaging(getAllParams);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading },
    actions: { onRowClick },
  };
};

const UniversitiesContext = createContext<any>({ state: {}, actions: {} });

export const UniversitiesContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UniversitiesContext.Provider value={value}>
      {children}
    </UniversitiesContext.Provider>
  );
};

export default function useUniversitiesContext() {
  return useContext<ReturnType<typeof Context>>(UniversitiesContext);
}
