import {
  faDeleteLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Row, Space, Typography } from "antd";
import { Button, Container, FieldInfo, InfoLanguage } from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import useNewsInfoContext from "../services/newsInfoContext";

const NewsInfo = () => {
  const [lang, setLang] = useState<"uz" | "en" | "ru">("uz");
  const {
    state: { data, loading },
    actions: { handleDelete },
  } = useNewsInfoContext();

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={19}>
        <Container loading={loading}>
          <div>
            <Typography.Title level={4}>Ma'lumot</Typography.Title>
            <Divider />
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <FieldInfo
                  title="Yanglik rasmi"
                  value={data?.image_url[lang]}
                  type="image"
                  style={{ height: 400 }}
                />
              </Col>
              <Col xs={24}>
                <FieldInfo
                  title="Yangilik sarlavhasi"
                  value={data?.title[lang]}
                  type="text"
                />
              </Col>
              <Col xs={24}>
                <FieldInfo
                  title="Yangilik matni"
                  value={data?.content[lang]}
                  type="text"
                  html
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Col>
      <Col xs={24} md={5}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <InfoLanguage onClick={(lang) => setLang(lang)} />
          <Link to={`/news/create/${data?._id}`}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "orange" }}
            >
              O'zgartirish
            </Button>
          </Link>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            block
            type="primary"
            onClick={handleDelete}
          >
            O'chirish
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default NewsInfo;
