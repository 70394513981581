import React from "react";
import SaleFunnel from "./SaleFunnel";
import { SaleFunnelContextProvider } from "../services/saleFunnelContex";

const index = () => {
  return (
    <SaleFunnelContextProvider>
      <SaleFunnel />
    </SaleFunnelContextProvider>
  );
};

export default index;
