import React from "react";
import { InfoModalContextProvider } from "../service/infoModalContext";
import InfoModal from "./InfoModal";

const index = () => {
  return (
    <InfoModalContextProvider>
      <InfoModal />
    </InfoModalContextProvider>
  );
};

export default index;
