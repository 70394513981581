import { PaymentMethodsContextProvider } from "../services/transactionTypesContext";
import TransactionTypes from "./TransactionTypes";

const index = () => {
  return (
    <PaymentMethodsContextProvider>
      <TransactionTypes />
    </PaymentMethodsContextProvider>
  );
};

export default index;
