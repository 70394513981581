import { FormProps, GetProp, UploadFile, UploadProps } from "antd";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const Context = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [props, setProps] = useState<{
    form: FormProps["form"];
    name: string | string[];
  }>();
  const defaultFile = props?.form?.getFieldValue(props.name);

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];
    let file = newFileList.slice(-1)[0];

    if (file) {
      if (file?.response?.statusCode) {
        if (file?.response?.statusCode === 200)
          props?.form?.setFieldValue(props.name, file?.response?.data);
        else props?.form?.setFieldValue(props.name, undefined);
      }
      setFileList([file]);
    } else {
      setFileList([]);
    }
  };

  useEffect(() => {
    if (props?.form && props.name) {
      if (defaultFile)
        if (typeof defaultFile === "string")
          setFileList([
            {
              uid: "-1",
              name: defaultFile,
              url: process.env.REACT_APP_BASE_URL + defaultFile,
              status: "done",
            },
          ]);
    }
  }, [defaultFile]);

  return {
    state: { previewOpen, previewImage, fileList },
    actions: {
      handlePreview,
      handleChange,
      setPreviewOpen,
      setPreviewImage,
      setProps,
      setFileList,
    },
  };
};

const UploadContext = createContext<any>({ state: {}, actions: {} });

export const UploadContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
  );
};

export default function useUploadContext() {
  return useContext<ReturnType<typeof Context>>(UploadContext);
}
