import { Space } from "antd";
import { Button } from "components/ui";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  &.ant-btn-primary {
    background-color: dodgerblue;
  }
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: rgb(83, 169, 255);
  }
  &.ant-btn-default {
    color: black;
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: dodgerblue;
    color: dodgerblue;
  }
`;

const InfoLanguage = ({
  onClick,
}: {
  onClick: (lang: "uz" | "ru" | "en") => void;
}) => {
  const [active, setActive] = useState<"uz" | "ru" | "en">("uz");

  const changeLanguage = (language: "uz" | "ru" | "en") => {
    setActive(language);
    onClick(language);
  };
  return (
    <Space style={{ width: "100%" }} styles={{ item: { width: "100%" } }}>
      <StyledButton
        block
        type={active === "uz" ? "primary" : "default"}
        onClick={() => changeLanguage("uz")}
      >
        O'zb
      </StyledButton>
      <StyledButton
        block
        type={active === "ru" ? "primary" : "default"}
        onClick={() => changeLanguage("ru")}
      >
        Rus
      </StyledButton>
      <StyledButton
        block
        type={active === "en" ? "primary" : "default"}
        onClick={() => changeLanguage("en")}
      >
        Eng
      </StyledButton>
    </Space>
  );
};

export default InfoLanguage;
