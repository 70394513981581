import { ProgramsInfoContextProvider } from "../services/programsInfoContext";
import ProgramsInfo from "./ProgramsInfo";

const index = () => {
  return (
    <ProgramsInfoContextProvider>
      <ProgramsInfo />
    </ProgramsInfoContextProvider>
  );
};

export default index;
