import { ApplicationDocumentsContextProvider } from "../services/applicationDocumentsContext";
import ApplicationDocuments from "./ApplicationDocuments";

const index = () => {
  return (
    <ApplicationDocumentsContextProvider>
      <ApplicationDocuments />
    </ApplicationDocumentsContextProvider>
  );
};

export default index;
