import { Col, Flex, Row, Steps } from "antd";
import { ProgramSelect, UserSelect } from "components/form";
import { Container } from "components/ui";
import useApplicationsAddContext from "../services/applicationsAddContext";

const ApplicationsAdd = () => {
  const {
    state: { steps, current, userId, programId, applicationId },
    actions: { setUserId, setProgramId },
  } = useApplicationsAddContext();
  return (
    <Container
      background={false}
      padding={false}
      border={false}
      overflow={false}
    >
      <Flex vertical style={{ height: "100%" }}>
        <Row style={{ paddingBottom: "20px" }} gutter={[20, 20]}>
          <Col md={24} lg={12}>
            <Steps items={steps} current={current} />
          </Col>
          <Col
            md={24}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 20,
            }}
          >
            {current === 0 && (
              <>
                <UserSelect
                  placeholder="Foydalanuvchi tanlang"
                  onSelect={(e) => setUserId(e)}
                  value={userId}
                  disabled={!!applicationId}
                />
                <ProgramSelect
                  placeholder="Dastur tanlang"
                  onSelect={(e) => setProgramId(e)}
                  value={programId}
                  disabled={!!applicationId}
                />
              </>
            )}
          </Col>
        </Row>
        {steps[current].content}
      </Flex>
    </Container>
  );
};

export default ApplicationsAdd;
