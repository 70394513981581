import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Space, Tag, Typography } from "antd";
import { IState } from "common/types/common";
import { useTranslation } from "react-i18next";

const states: IState[] = [
  {
    _id: "66e074d6550a853d11939c8c",
    name: "Yangi",
    color: "#FFB800",
    order: 1,
    is_default: true,
    type: "new",
  },
  {
    _id: "66e074d6550a853d11939c8d",
    name: "Birinchi to'lov qilindi",
    color: "#2F84D3",
    order: 2,
    is_default: true,
    type: "first_payment",
  },
  {
    _id: "66e074d6550a853d11939c8e",
    name: "Jarayonda",
    color: "#FFB800",
    order: 3,
    is_default: true,
    type: "in_progress",
  },
  {
    _id: "66e074d6550a853d11939c8f",
    name: "Yetishmovchilik",
    color: "#FFB800",
    order: 4,
    is_default: true,
    type: "incomplete",
  },
  {
    _id: "66e074d6550a853d11939c90",
    name: "Dastur tolovi",
    color: "#0099B5",
    order: 5,
    is_default: true,
    type: "second_payment",
  },
  {
    _id: "66e074d6550a853d11939c91",
    name: "Tayorlandi",
    color: "#36CF00",
    order: 6,
    is_default: true,
    type: "completed",
  },
  {
    _id: "66e6a38095c96776b9964033",
    name: "Bekor qilingan",
    color: "#d32f2f",
    order: 7,
    is_default: true,
    type: "canceled",
  },
];

const StatusText = ({ state }: { state: IState | undefined }) => {
  const { t } = useTranslation();
  return (
    <Popover
      content={
        <Space direction="vertical">
          {states?.map((item) => (
            <div>
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: item.color, marginRight: 5 }}
              />
              <Typography.Text>{item.name}</Typography.Text>
            </div>
          ))}
        </Space>
      }
    >
      <Tag color={state?.color}>{t(`constants.states.${state?.type}`)}</Tag>
    </Popover>
  );
};

export default StatusText;
