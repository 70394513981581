import { Api } from "../api";
import { ApiPaging, ApiResponse, paramApi, IBranch } from "common/types/common";

export class BranchApi extends Api {
  async addEmployee(data: any, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "organization/update" : "organization/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IBranch[]>>>(
      "post",
      "organization/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IBranch>>(
      "get",
      `organization/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<IBranch>>(
      "delete",
      `organization/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
