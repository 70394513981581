import {
  Avatar,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { Button, Container } from "components/ui";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import useEmployeesInfoContext from "../services/employeesInfoContext";
import term from "utils/term";

const EmployeesInfo = () => {
  const {
    state: { profile },
  } = useEmployeesInfoContext();
  return (
    <Container background={false} padding={false}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Row gutter={[20, 20]}>
          <Col xs={24} md={8}>
            <Container height={false} style={{ height: "100%" }}>
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%" }}
              >
                <Avatar size={75} icon={<UserOutlined />} />
                <Typography.Title level={4}>
                  {profile?.full_name}
                </Typography.Title>
              </Space>
              <Divider />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>
                  <strong>Telefon raqami: </strong>
                  {profile?.phone_number}
                </Typography.Text>
                <Typography.Text>
                  <strong>Email: </strong>
                  {profile?.email}
                </Typography.Text>
                <Typography.Text>
                  <strong>Jinsi: </strong>
                  {term.gender(profile?.gender)}
                </Typography.Text>
                <Typography.Text>
                  <strong>Filiali: </strong>
                  {/* {profile} */}-
                </Typography.Text>
                <Typography.Text>
                  <strong>Foiz: </strong>
                  {profile?.kpi_percent}
                </Typography.Text>
                <Typography.Text>
                  <strong>Paroli: </strong>
                  {profile?.password}
                </Typography.Text>
              </Space>
              <Space
                style={{ marginTop: 20, width: "100%" }}
                styles={{ item: { width: "100%" } }}
              >
                <Button
                  icon={<FontAwesomeIcon icon={faEdit} />}
                  block
                  type="primary"
                  style={{ backgroundColor: "orange" }}
                >
                  O'zgartirish
                </Button>
                <Button
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  block
                  type="primary"
                >
                  O'chirish
                </Button>
              </Space>
            </Container>
          </Col>
          <Col xs={24} md={16}>
            <Container height={false} style={{ height: "100%" }}>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                split={<Divider style={{ margin: "5px 0" }} />}
              >
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text strong>Oylik tushumi</Typography.Text>
                  <Typography.Text>31 450 000 UZS</Typography.Text>
                </Flex>
              </Space>
            </Container>
          </Col>
        </Row>
        <Container height={false}>
          <Tabs
            // onChange={onChange}
            type="card"
            items={new Array(3).fill(null).map((_, i) => {
              const id = String(i + 1);
              return {
                label: `Tab ${id}`,
                key: id,
                children: <Table />,
              };
            })}
          />
        </Container>
      </Space>
    </Container>
  );
};

export default EmployeesInfo;
