import api from "common/api";
import { IEmployee } from "common/types/employee";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const Context = () => {
  const [profile, setProfile] = useState<IEmployee>();

  const getProfile = async () => {
    try {
      const response = await api.employee.getProfile();
      setProfile(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return {
    state: { profile },
    actions: {},
  };
};

const EmployeesInfoContext = createContext<any>({ state: {}, actions: {} });

export const EmployeesInfoContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <EmployeesInfoContext.Provider value={value}>
      {children}
    </EmployeesInfoContext.Provider>
  );
};

export default function useEmployeesInfoContext() {
  return useContext<ReturnType<typeof Context>>(EmployeesInfoContext);
}
