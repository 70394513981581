import { UniversitiesAddContextProvider } from "../services/universitiesAddContext";
import UniversitiesAdd from "./UniversitiesAdd";

const index = () => {
  return (
    <UniversitiesAddContextProvider>
      <UniversitiesAdd />
    </UniversitiesAddContextProvider>
  );
};

export default index;
