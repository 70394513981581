import {
  faArrowLeft,
  faBell,
  faLock,
  faPlus,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  Divider,
  Flex,
  Input,
  Layout,
  Popover,
  Select,
  Space,
  Typography,
} from "antd";
import logo from "assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import { Button, LanguageSwitcher } from "components/ui";
import React from "react";
import "../style/Navbar.css";
import useAuth from "hooks/useAuth";
import { BranchSelect } from "components/form";

const StyledHeader = styled(Layout.Header)`
  padding: 0 20px;
  background-color: white;
  border-block-end: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  .logo {
    width: calc(250px - 20px);
    display: flex;
    align-items: center;
  }
`;

const iconStyle: React.CSSProperties = {
  padding: "5px",
  backgroundColor: "#D94042",
  color: "white",
  borderRadius: 7,
  width: 12,
  height: 12,
  cursor: "pointer",
};

const headerStyle: React.CSSProperties = {};

const Navbar = () => {
  const navigate = useNavigate();
  const { removeAuth } = useAuth();

  const logOut = () => {
    removeAuth();
  };

  const Notifications = () => {
    return (
      <div className="notifications">
        {Array.from({ length: 10 }, (_, index) => index).map((item, index) => (
          <Space key={index} className="notifications-item">
            <Flex justify="space-between" align="center">
              <Link to="">
                <Typography.Text strong>Yangi o'quvchi</Typography.Text>
              </Link>
              <Typography.Text type="secondary">20:20</Typography.Text>
            </Flex>
            <Typography.Text type="secondary">Yangi o'quvchi</Typography.Text>
            <Divider style={{ margin: "10px 0" }} />
          </Space>
        ))}
      </div>
    );
  };

  const Account = () => {
    return (
      <Space className="account-popover" direction="vertical" size="large">
        <Space className="account-title">
          <Avatar>AF</Avatar>
          <Typography.Text strong>Alyarov Faxriyor</Typography.Text>
        </Space>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            block
            icon={<FontAwesomeIcon icon={faSignOut} />}
            type="text"
            onClick={logOut}
          >
            Chiqish
          </Button>
        </Space>
      </Space>
    );
  };
  return (
    <StyledHeader style={headerStyle}>
      <div className="logo">
        <img src={logo} width={150} />
      </div>
      <Flex
        justify="space-between"
        align="center"
        style={{ width: "calc(100% - 230px)" }}
      >
        <Space size="middle">
          <Typography.Text
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 7,
            }}
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={iconStyle} /> Orqaga
          </Typography.Text>
          <BranchSelect />
          <Input
            placeholder="Qidirish..."
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
        </Space>
        <Flex gap={15} align="center">
          <LanguageSwitcher />
          <FontAwesomeIcon
            icon={faPlus}
            style={iconStyle}
            onClick={() => navigate(`/applications/create`)}
          />
          {/* <Popover
            placement="bottomLeft"
            title="Xabarlar"
            content={Notifications}
            arrow={undefined}
            trigger="click"
          >
            <Badge count={5} size="small">
              <FontAwesomeIcon
                icon={faBell}
                style={{ color: "#D94042", cursor: "pointer" }}
                size="xl"
              />
            </Badge>
          </Popover> */}
          <Popover
            placement="bottomLeft"
            content={Account}
            arrow={undefined}
            trigger="click"
          >
            <Avatar style={{ marginLeft: 20, cursor: "pointer" }}>AF</Avatar>
          </Popover>
        </Flex>
      </Flex>
    </StyledHeader>
  );
};

export default Navbar;
