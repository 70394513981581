import { IApplication } from "common/types/applications";
import { CardContextProvider } from "../services/cardContext";
import Card from "./Card";

export interface ICard {
  task: IApplication;
  index: number;
}

const index = (props: ICard) => {
  return (
    <CardContextProvider>
      <Card {...props} />
    </CardContextProvider>
  );
};

export default index;
