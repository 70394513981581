import { ApplicationsContextProvider } from "../services/applicationsContext";
import Applications from "./Applications";

const index = () => {
  return (
    <ApplicationsContextProvider>
      <Applications />
    </ApplicationsContextProvider>
  );
};

export default index;
