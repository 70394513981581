import { PaymentMethodsAddContextProvider } from "../services/paymentMethodsAddContext";
import PaymentMethodsAdd from "./PaymentMethodsAdd";

const index = () => {
  return (
    <PaymentMethodsAddContextProvider>
      <PaymentMethodsAdd />
    </PaymentMethodsAddContextProvider>
  );
};

export default index;
