import { Col, Divider, Row, Typography } from "antd";
import { IApplication } from "common/types/applications";
import { FieldInfo } from "components/ui";
import { useTranslation } from "react-i18next";

const ApplicationInfo = ({ task }: { task: IApplication | undefined }) => {
  const { i18n } = useTranslation();

  const data = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
    {
      title: "Ant Design Title 4",
    },
  ];

  return task ? (
    <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={8}>
          <FieldInfo type="text" title="Ariza raqami" value={task.number} />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="text"
            title="Dastur nomi"
            value={task?.program?.name[i18n.language as "uz" | "en" | "ru"]}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="text"
            title="Dastur ID"
            value={task.program?.number}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="date"
            title="Berilgan sana"
            value={task.created_at}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="date"
            title="Ohirgi o'zgartirish kiritilgan sana"
            value={task.updated_at}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo type="text" title="Xolati" value={task.state?.name} />
        </Col>
      </Row>
      <Divider dashed />
      <Typography.Title level={5}>Ariza tarixi</Typography.Title>
      {/* <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar>AF</Avatar>}
              title={item.title}
              description="Xodim"
            />
            <Typography.Text type="secondary">
              {moment().format("l")}
            </Typography.Text>
          </List.Item>
        )}
      /> */}
    </div>
  ) : null;
};

export default ApplicationInfo;
