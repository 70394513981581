import React from "react";
import { BoardContextProvider } from "../services/boardContext";
import Board from "./Board";
import { IState } from "common/types/common";

export interface IBoard {
  states: IState[];
  children?: React.ReactNode;
}

const index = (props: IBoard) => {
  return (
    <BoardContextProvider {...props}>
      <Board {...props} />
    </BoardContextProvider>
  );
};

export default index;
