import { Form, FormProps, message } from "antd";
import api from "common/api";
import { ICashbox } from "common/types/cashbox";
import { ICity } from "common/types/common";
import { FieldData } from "rc-field-form/es/interface";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      const payload = {
        ...values,
      };
      const response = await api.cashbox.addCashbox(payload, id);
      message.success(id ? "Kassa o'zgartirildi" : "Kassa yaratildi");
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const getAll = async () => {
    try {
      const response = await api.cashbox.getAll();
      const foundCashbox = response.data.find(
        (cashbox) => cashbox._id === id
      ) as ICashbox;
      const fields: FieldData[] = (
        Object.keys(foundCashbox) as (keyof ICashbox)[]
      ).map((key) => ({
        name: key,
        value: foundCashbox[key],
      }));
      form.setFields(fields);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.cashbox.delete(id);
        message.success("Kassa o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    getAll();
  }, []);

  return {
    state: { form },
    actions: { onFinish, handleDelete },
  };
};

const CashboxesAddContext = createContext<any>({
  state: {},
  actions: {},
});

export const CashboxesAddContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <CashboxesAddContext.Provider value={value}>
      {children}
    </CashboxesAddContext.Provider>
  );
};

export default function useCashboxesAddContext() {
  return useContext<ReturnType<typeof Context>>(CashboxesAddContext);
}
