import type { TableProps } from "antd";
import { Table as AntTable } from "antd";
import styled from "styled-components";

const StyledTable = styled(AntTable)`
  .ant-pagination {
    padding-right: 20px;
  }
`;

const Table = (props: TableProps) => {
  return (
    <StyledTable
      {...props}
      sticky={true}
      scroll={{ y: window.innerHeight - 275 }}
    />
  );
};

export default Table;
