import api from "common/api";
import { IApplication } from "common/types/applications";
import { IState } from "common/types/common";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Context = () => {
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IApplication[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [taskInfo, setTaskInfo] = useState<IApplication>();
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "branch",
      label: "Filial",
      name: "branch",
    },
    {
      type: "date",
      label: "Sana",
      name: "date",
      range: true,
    },
    {
      type: "employee",
      label: "Xodim",
      name: "employee",
    },
    {
      type: "search",
      // label: "Turi",
      name: "search",
    },
  ];

  const getStates = async () => {
    try {
      setLoading(true);
      const response = await api.common.stateGetAll();
      setStates(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    // getPaging();
    getStates();
  }, [getAllParams]);

  return {
    state: { filterFields, data, loading, states, isOpen, taskInfo },
    actions: { setIsOpen, setTaskInfo, getStates },
  };
};

const ApplicationsContext = createContext<any>({ state: {}, actions: {} });

export const ApplicationsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ApplicationsContext.Provider value={value}>
      {children}
    </ApplicationsContext.Provider>
  );
};

export default function useApplicationsContext() {
  return useContext<ReturnType<typeof Context>>(ApplicationsContext);
}
