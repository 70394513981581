import { UsersInfoContextProvider } from "../services/usersInfoContext";
import UsersInfo from "./UsersInfo";

const index = () => {
  return (
    <UsersInfoContextProvider>
      <UsersInfo />
    </UsersInfoContextProvider>
  );
};

export default index;
