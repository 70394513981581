import React from "react";
import { ApplicationsAddContextProvider } from "../services/applicationsAddContext";
import ApplicationsAdd from "./ApplicationsAdd";

const index = () => {
  return (
    <ApplicationsAddContextProvider>
      <ApplicationsAdd />
    </ApplicationsAddContextProvider>
  );
};

export default index;
