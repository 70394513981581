import React from "react";
import EmployeesInfo from "./EmployeesInfo";
import { EmployeesInfoContextProvider } from "../services/employeesInfoContext";

const index = () => {
  return (
    <EmployeesInfoContextProvider>
      <EmployeesInfo />
    </EmployeesInfoContextProvider>
  );
};

export default index;
