import React from "react";
import { CashboxesContextProvider } from "../services/cashboxesContext";
import Cashboxes from "./Cashboxes";

const index = () => {
  return (
    <CashboxesContextProvider>
      <Cashboxes />
    </CashboxesContextProvider>
  );
};

export default index;
