import { Form, FormProps, message, TableProps } from "antd";
import api from "common/api";
import { ICashbox } from "common/types/cashbox";
import { ITransaction, ITransactionPayload } from "common/types/transaction";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import moment from "moment";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useTranslation } from "react-i18next";
import { format } from "utils/format";

interface DataType extends ITransaction {}

export interface ICashboxesFilter {
  search: string;
  payment_method_id: string;
  transaction_type_id: string;
  date_from?: string;
  date_to?: string;
}

const Context = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<{
    cashboxes: boolean;
    transactions: boolean;
  }>({ cashboxes: true, transactions: false });
  const [cashboxes, setCashboxes] = useState<ICashbox[]>();
  const [selected, setSelected] = useState<string>();
  const [action, setAction] = useState<{
    open: boolean;
    type: "income" | "expense" | null;
    loading?: boolean;
  }>();
  const [transactions, setTransactions] = useState<ITransaction[]>();
  const { i18n } = useTranslation();
  const { getAllParams } = useQueryParams();

  const filterFields: IField[] = [
    {
      type: "transaction-type",
      label: "Tranzaksiya turi",
      name: "transaction_type_id",
    },
    {
      type: "payment-method",
      label: "To'lov usuli",
      name: "payment_method_id",
    },
    {
      type: "date",
      label: "Sana",
      name: "date",
      range: true,
    },
    // {
    //   type: "search",
    // label: "Turi",
    //   name: "search",
    // },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Sana",
        dataIndex: "created_at",
        key: "created_at",
        render: (data) => moment(data).format("MM/D/YYYY, h:mm:ss"),
      },
      {
        title: "Miqdori",
        dataIndex: "amount",
        key: "amount",
        render: (data) => format.money(data, "UZS"),
      },
      // {
      //   title: "Izohi",
      //   dataIndex: "description",
      //   key: "description",
      // },
    ],
    [i18n.language]
  );

  const getCashboxes = async () => {
    try {
      setLoading((prev) => ({ ...prev, cashboxes: true }));
      const response = await api.cashbox.getAll();
      setCashboxes(response.data);
      if (!selected) {
        setSelected(response.data[0]._id);
        getTransactions(response.data[0]._id);
      } else {
        getTransactions(selected);
      }
      setLoading((prev) => ({ ...prev, cashboxes: false }));
    } catch (err) {
      setLoading((prev) => ({ ...prev, cashboxes: false }));
      console.log(err);
    }
  };

  const getTransactions = async (id: string) => {
    try {
      setLoading((prev) => ({ ...prev, transactions: true }));
      const response = await api.transaction.getPaging({
        limit: 100,
        page: 1,
        cashbox_id: id,
        ...getAllParams,
      });
      setTransactions(response.data.data);
      setLoading((prev) => ({ ...prev, transactions: false }));
    } catch (err) {
      setLoading((prev) => ({ ...prev, transactions: false }));
      console.log(err);
    }
  };

  const selectCashbox = (id: string) => {
    setSelected(id);
    getTransactions(id);
  };

  const handleAction = (type: "income" | "expense") => {
    setAction({ open: true, type });
  };
  const handleCloseAction = () => {
    setAction({ open: false, type: null, loading: false });
    form.resetFields();
  };

  const onFinish: FormProps["onFinish"] = async (
    values: ITransactionPayload
  ) => {
    try {
      if (action?.type && selected) {
        setAction((prev) => (prev ? { ...prev, loading: true } : undefined));
        const response = await api.transaction.addTransaction(
          { ...values, cashbox_id: selected },
          action.type
        );
        getCashboxes();
        handleCloseAction();
        message.success(
          `Muvaffaqiyatli ${
            action.type === "income" ? "kirim" : "chiqim"
          } qilindi`
        );
      }
    } catch (err) {
      setAction({ open: false, type: null, loading: false });
      message.success(
        `${action?.type === "income" ? "Kirim" : "Chiqim"} qilishda xatolik`
      );
      console.log(err);
    }
  };

  useEffect(() => {
    getCashboxes();
  }, []);

  useEffect(() => {
    if (selected) {
      getTransactions(selected);
    }
  }, [getAllParams]);
  return {
    state: {
      cashboxes,
      loading,
      selected,
      action,
      form,
      filterFields,
      columns,
      transactions,
    },
    actions: { selectCashbox, handleAction, handleCloseAction, onFinish },
  };
};

const CashboxesContext = createContext<any>({ state: {}, actions: {} });

export const CashboxesContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <CashboxesContext.Provider value={value}>
      {children}
    </CashboxesContext.Provider>
  );
};

export default function useCashboxesContext() {
  return useContext<ReturnType<typeof Context>>(CashboxesContext);
}
