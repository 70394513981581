import React from "react";
import { LaneContextProvider } from "../services/laneContext";
import Lane from "./Lane";
import { Task } from "../../../services/boardTypes";
import { IState } from "common/types/common";

export interface ILane {
  column: IState;
  tasks?: Task[];
}

const index = (props: ILane) => {
  return (
    <LaneContextProvider>
      <Lane {...props} />
    </LaneContextProvider>
  );
};

export default index;
