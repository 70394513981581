import { Col, Row } from "antd";
import { IApplication } from "common/types/applications";
import { FieldInfo, Loading } from "components/ui";
import React from "react";
import useDocumentsContext from "../services/documentsContext";
import { useTranslation } from "react-i18next";

const Documents = ({ task }: { task: IApplication | undefined }) => {
  const { i18n } = useTranslation();
  const {
    state: { documents, userDocuments, loading },
  } = useDocumentsContext();
  return task ? (
    <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={[20, 20]}>
          {documents?.map((document) => (
            <Col xs={12} md={8}>
              <FieldInfo
                type="text"
                title={document.name[i18n.language as "uz" | "en" | "ru"]}
                value={
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}${
                      userDocuments?.find(
                        (item) => item.doc_id === document._id
                      )?.value
                    }`}
                  >
                    Faylni yuklash
                  </a>
                }
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  ) : null;
};

export default Documents;
