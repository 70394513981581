import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableProps } from "antd";
import api from "common/api";
import { IPaymentMethod } from "common/types/common";
import { Button } from "components/ui";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface DataType extends IPaymentMethod {}

export interface IEmployeesFilter {
  search: string;
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IPaymentMethod[]>([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Nomi",
        dataIndex: "name",
        key: "name",
      },
      {
        dataIndex: "_id",
        key: "_id",
        render: (id) => (
          <Link to={`/management/payment-methods/create/${id}`}>
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faEdit} />}
              style={{ backgroundColor: "orange" }}
            >
              O'zgartirish
            </Button>
          </Link>
        ),
        width: 300,
      },
    ],
    [i18n.language]
  );

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.paymentMethod.getPaging(getAllParams);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading },
    actions: {},
  };
};

const PaymentMethodsContext = createContext<any>({ state: {}, actions: {} });

export const PaymentMethodsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <PaymentMethodsContext.Provider value={value}>
      {children}
    </PaymentMethodsContext.Provider>
  );
};

export default function usePaymentMethodsContext() {
  return useContext<ReturnType<typeof Context>>(PaymentMethodsContext);
}
