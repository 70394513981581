import { Card, Flex, Form, Input, Typography } from "antd";
import { PhoneNumberInput } from "components/form";
import { Button } from "components/ui";
import { useTranslation } from "react-i18next";
import useLoginContext from "../services/loginContext";
import { loginFields } from "../services/loginTypes";
import "../style/Login.css";

const Login = () => {
  const { t } = useTranslation();

  const {
    state: { form, loading },
    actions: { onFinish },
  } = useLoginContext();

  return (
    <Flex className="login" align="center" justify="center">
      <Card style={{ minWidth: 400, borderRadius: 20 }}>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          {t("login.title")}
        </Typography.Title>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item<loginFields>
            name="phone_number"
            label={t("login.phone_number")}
            required
            rules={[{ required: true }]}
          >
            <PhoneNumberInput form={form} name="phone_number" />
          </Form.Item>
          <Form.Item<loginFields>
            name="password"
            label={t("login.password")}
            required
            rules={[{ required: true }]}
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              {t("login.submit")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Flex>
  );
};

export default Login;
