import { INews } from "common/types/news";
import { ApiPaging, ApiResponse, paramApi } from "../../types/common";
import { Api } from "../api";

export class NewsApi extends Api {
  async addNews(data: any, id?: string) {
    return await this.execute<ApiResponse<INews>>(
      id ? "put" : "post",
      id ? "news/update" : "news/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<INews[]>>>(
      "post",
      "news/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<INews>>(
      "get",
      `news/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `news/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
