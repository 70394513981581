import { Form, FormProps, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../common/api";
import { useTranslation } from "react-i18next";

interface ICitySelect {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
  multiple?: boolean;
  [key: string]: unknown;
}

const CitySelect = ({
  label,
  placeholder = "Tanlang",
  form,
  name,
  onSelect,
  multiple = false,
  ...props
}: ICitySelect) => {
  const { i18n } = useTranslation();
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };

  const cityGetAll = async () => {
    try {
      setLoading(true);
      const response = await api.common.cityGetAll();
      setOptions(
        response.data.map((city) => ({
          label: city.name[i18n.language as "uz" | "ru" | "en"],
          value: city._id,
        }))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    cityGetAll();
  }, []);

  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }} {...props}>
      <Select
        mode={multiple ? "multiple" : undefined}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        loading={loading}
      />
    </Form.Item>
  );
};

export default CitySelect;
