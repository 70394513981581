import React from "react";
import { UploadContextProvider } from "../services/uploadContext";
import Upload from "./Upload";
import { FormProps, UploadProps } from "antd";

export interface IUpload extends Omit<UploadProps, "name"> {
  form: FormProps["form"];
  name: string | string[];
  multiple?: boolean;
  children: React.ReactNode;
}

const index = (props: IUpload) => {
  return (
    <UploadContextProvider>
      <Upload {...props} />
    </UploadContextProvider>
  );
};

export default index;
