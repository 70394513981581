import api from "common/api";
import { IApplicationStatistics } from "common/types/applications";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const Context = () => {
  const [statistics, setStatistics] = useState<IApplicationStatistics>();
  const [loading, setLoading] = useState(false);
  const { getAllParams } = useQueryParams();

  const getStatistics = async () => {
    try {
      setLoading(true);
      const response = await api.application.statistics(getAllParams);
      setStatistics(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, [getAllParams]);
  return {
    state: { loading, statistics },
    actions: {},
  };
};

const SaleFunnelContext = createContext<any>({ state: {}, actions: {} });

export const SaleFunnelContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <SaleFunnelContext.Provider value={value}>
      {children}
    </SaleFunnelContext.Provider>
  );
};

export default function useSaleFunnelContext() {
  return useContext<ReturnType<typeof Context>>(SaleFunnelContext);
}
