import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Row, Space, Typography } from "antd";
import { Button, Container, FieldInfo, InfoLanguage } from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import useUniversitiesInfoContext from "../services/universitiesInfoContext";

const UniversitiesInfo = () => {
  const [lang, setLang] = useState<"uz" | "en" | "ru">("uz");
  const {
    state: { data, loading },
    actions: { handleDelete },
  } = useUniversitiesInfoContext();

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={19}>
        <Container padding={false} background={false} loading={loading}>
          <Space direction="vertical" size="large">
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Ma'lumot</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                <Col xs={24} md={4}>
                  <FieldInfo
                    title="Logotip"
                    value={data?.logo_img_url}
                    type="image"
                    style={{ objectFit: "contain" }}
                  />
                </Col>
                <Col xs={24} md={20}>
                  <FieldInfo
                    title="Universitetning asosiy rasmi"
                    value={data?.main_img_url}
                    type="image"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Universitet nomi"
                    value={data?.name[lang]}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Shahar"
                    value={data?.city.name[lang]}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Turi"
                    value={data?.type === "private" ? "Shaxsiy" : "Ommaviy"}
                    type="text"
                  />
                </Col>
                <Col xs={24} md={24}>
                  <FieldInfo
                    title="Universitet haqida video"
                    value={data?.video_url[lang]}
                    type="youtube"
                  />
                </Col>
                <Col xs={24} md={24}>
                  <FieldInfo
                    title="Universitet haqida batafsil"
                    value={data?.description[lang]}
                    type="text"
                  />
                </Col>
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>O'rinlar</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                {data?.rankings.map((item) => (
                  <>
                    <Col xs={24} md={4}>
                      <FieldInfo value={item?.rank} type="text" />
                    </Col>
                    <Col xs={24} md={20}>
                      <FieldInfo value={item?.title} type="text" />
                    </Col>
                  </>
                ))}
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Kirish</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                <Col xs={24} md={12}>
                  <FieldInfo
                    title="Tashkil topgan yili"
                    value={data?.established_year}
                    type="text"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldInfo
                    title="Studentlar soni"
                    value={data?.student_count}
                    type="text"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldInfo
                    title="Xalqaro studentlar soni"
                    value={data?.international_students}
                    type="text"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldInfo
                    title="Fakultetlar soni"
                    value={data?.department_count}
                    type="text"
                  />
                </Col>
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Gelereya</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                {data?.gallery?.map((item) => (
                  <Col xs={12} md={6}>
                    <FieldInfo value={item} type="image" />
                  </Col>
                ))}
              </Row>
            </Container>
          </Space>
        </Container>
      </Col>
      <Col xs={24} md={5}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <InfoLanguage onClick={(lang) => setLang(lang)} />
          <Link to={`/universities/create/${data?._id}`}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "orange" }}
            >
              O'zgartirish
            </Button>
          </Link>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            block
            type="primary"
            onClick={handleDelete}
          >
            O'chirish
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default UniversitiesInfo;
