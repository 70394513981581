import {
  Form,
  FormProps,
  Select,
  SelectProps,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import api from "../../../common/api";

interface IUserSelect {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
  multiple?: boolean;
  value?: any;
  disabled?: boolean;
  [key: string]: unknown;
}

const UserSelect = ({
  label,
  placeholder = "Tanlang",
  form,
  name,
  onSelect,
  multiple = false,
  value,
  disabled = false,
  ...props
}: IUserSelect) => {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };

  const userGetAll = async () => {
    try {
      setLoading(true);
      const response = await api.users.getPaging({ page: 1, limit: 50 });
      setOptions(
        response.data.data.map((user) => ({
          label:
            user.first_name || user.last_name || user.family_name
              ? `${user.first_name || ""} ${user.last_name || ""} ${
                  user.family_name || ""
                }`
              : user.phone_number,
          value: user._id,
        }))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userGetAll();
  }, []);

  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }} {...props}>
      <Select
        mode={multiple ? "multiple" : undefined}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        loading={loading}
        value={value}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default UserSelect;
