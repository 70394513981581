import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import i18n from "./i18n";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const lng = localStorage.getItem("language") || "uz";

i18n.changeLanguage(lng).then(() => root.render(<App />));
