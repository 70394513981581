import { IUniversities } from "common/types/universities";
import { ApiPaging, ApiResponse, ICity, paramApi } from "../../types/common";
import { Api } from "../api";

export class UniversityApi extends Api {
  async addUniversity(data: any, id?: string) {
    return await this.execute<ApiResponse<IUniversities>>(
      id ? "put" : "post",
      id ? "university/update" : "university/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IUniversities[]>>>(
      "post",
      "university/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IUniversities>>(
      "get",
      `university/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `university/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
