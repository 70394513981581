import { FormProps, message } from "antd";
import api from "common/api";
import { IAddApplication } from "common/api/modules/applications";
import { IUsers } from "common/types/users";
import moment from "moment";
import React, { FC, createContext, useContext, useEffect } from "react";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";

export interface FieldType extends Omit<IUsers, "birth_date" | "valid_until"> {
  birth_date: Date;
  valid_until: Date;
  program_id: string;
}

const Context = () => {
  const {
    state: { programId, applicationId },
    actions: {
      setCurrent,
      setUserId,
      setProgramId,
      setApplicationId,
      setApplication,
    },
  } = useApplicationsAddContext();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    if (!programId) {
      message.error("Dastur tanlang");
      return;
    }
    createApplication({
      ...values,
      birth_date: moment(values.birth_date).toISOString(),
      valid_until: moment(values.valid_until).toISOString(),
      program_id: programId,
    });
  };

  const createApplication = async (payload: IAddApplication) => {
    try {
      const response = await api.application.addApplication(
        payload,
        applicationId
      );
      setUserId(response.data.user_id);
      setProgramId(response.data.program_id);
      setApplicationId(response.data._id);
      message.success("Arizangiz yaratildi!");
      setCurrent((prev) => prev + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const getById = async (id: string) => {
    try {
      const response = await api.application.getById(id);
      setUserId(response.data.user_id);
      setProgramId(response.data.program_id);
      setApplication(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (applicationId) getById(applicationId);
  }, [applicationId]);

  return {
    state: {},
    actions: { onFinish },
  };
};

const ApplicationUserInfoContext = createContext<any>({
  state: {},
  actions: {},
});

export const ApplicationUserInfoContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ApplicationUserInfoContext.Provider value={value}>
      {children}
    </ApplicationUserInfoContext.Provider>
  );
};

export default function useApplicationUserInfoContext() {
  return useContext<ReturnType<typeof Context>>(ApplicationUserInfoContext);
}
