import { Api } from "../api";
import {
  ApiPaging,
  ApiResponse,
  paramApi,
  IPaymentMethod,
} from "common/types/common";

export class PaymentMethodApi extends Api {
  async addPaymentMethod(data: IPaymentMethod, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "payment-method/update" : "payment-method/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IPaymentMethod[]>>>(
      "post",
      "payment-method/paging",
      data,
      null,
      undefined
    );
  }

  async getChoose(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IPaymentMethod[]>>>(
      "post",
      "payment-method/choose",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IPaymentMethod>>(
      "get",
      `payment-method/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<IPaymentMethod>>(
      "delete",
      `payment-method/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
