import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Form, FormProps, Grid, Space } from "antd";
import {
  SearchInput,
  Select,
  CitySelect,
  UniversitySelect,
  DatePicker,
  EmployeeSelect,
  BranchSelect,
  TransactionTypeSelect,
  PaymentMethodSelect,
} from "components/form";
import { Button } from "components/ui";
import useQueryParams from "hooks/useQueryParams";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFilterContext from "../services/filterContext";
import dayjs from "dayjs";

export interface IField {
  type:
    | "select"
    | "input"
    | "search"
    | "city"
    | "date"
    | "university"
    | "employee"
    | "branch"
    | "transaction-type"
    | "payment-method";
  name: string;
  options?: {
    id?: number;
    label: string;
    value: string | any;
  }[];
  label?: string;
  placeholder?: string;
  mode?: "multiple" | "tags";
  range?: boolean;
}

export interface IFilter<FieldType> {
  initialValues?: FieldType[];
  filterFields: IField[];
  onFinish?: FormProps<FieldType>["onFinish"];
  onFinishFailed?: FormProps<FieldType>["onFinishFailed"];
}

const Filter = <FieldType,>({
  initialValues,
  filterFields,
  onFinish,
  onFinishFailed,
}: IFilter<FieldType>) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { getAllParams } = useQueryParams();
  const screens = Grid.useBreakpoint();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const {
    state: { form },
    actions: { handleSearch, handleSelect, handleDate },
  } = useFilterContext();

  const renderField = (field: IField) => {
    switch (field.type) {
      case "search":
        return (
          <SearchInput
            label={field.label}
            name={field.name}
            form={form}
            onChange={(value) => handleSearch(field.name, value)}
          />
        );
      case "select":
        return (
          <Select
            label={field.label}
            placeholder={field.placeholder}
            options={field.options!}
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
          />
        );
      case "city":
        return (
          <CitySelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      case "university":
        return (
          <UniversitySelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      case "date":
        return (
          <DatePicker
            name={field.name}
            form={form}
            onChange={(value) => handleDate(field.name, value)}
            range={field.range}
            label={field.label}
          />
        );
      case "employee":
        return (
          <EmployeeSelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      case "branch":
        return (
          <BranchSelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      case "transaction-type":
        return (
          <TransactionTypeSelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      case "payment-method":
        return (
          <PaymentMethodSelect
            name={field.name}
            form={form}
            onSelect={(value) => handleSelect(field.name, value)}
            label={field.label}
            placeholder={field.placeholder}
          />
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    const paramFields = getAllParams;
    Object.entries(paramFields)?.map(([key, value]): any => {
      filterFields?.map((field) => {
        if (key === field.name) {
          if (field.type === "date") {
            if (value) form?.setFieldValue(key, dayjs(value as string));
          } else {
            form?.setFieldValue(key, value);
          }
        } else if (key === "date_from" || "date_to") {
          if (paramFields?.["date_from"])
            form?.setFieldValue("date", [
              dayjs(paramFields?.["date_from"]),
              dayjs(paramFields?.["date_to"]),
            ]);
        }
      });
    });
  }, [getAllParams]);
  return (
    <Space
      style={{ marginBottom: 10, width: "100%" }}
      styles={{ item: { width: "100%" } }}
    >
      {screens.xs || (screens.sm && !screens.lg) ? (
        <>
          <Button
            type="primary"
            shape="round"
            icon={<FontAwesomeIcon icon={faSliders} />}
            onClick={showDrawer}
          >
            {t("components.filter.drawer_title")}
          </Button>
          <Drawer
            title={t("components.filter.drawer_title")}
            onClose={onClose}
            open={open}
          >
            <Form
              form={form}
              name="filter"
              layout="vertical"
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {filterFields?.map((field) => {
                  return renderField(field);
                })}
              </Space>
            </Form>
          </Drawer>
        </>
      ) : (
        <Form
          form={form}
          name="filter"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
            wrap
          >
            {filterFields?.map((field) => {
              return renderField(field);
            })}
          </Space>
        </Form>
      )}
    </Space>
  );
};

export default Filter;
