import { IEmployee, ISalaryEmployee } from "common/types/employee";
import { ApiPaging, ApiResponse, paramApi } from "../../types/common";
import { Api } from "../api";

export class EmployeeApi extends Api {
  async addEmployee(data: any, id?: string) {
    return await this.execute<ApiResponse<IEmployee>>(
      id ? "put" : "post",
      id ? "employee/update" : "employee/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IEmployee[]>>>(
      "post",
      "employee/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IEmployee>>(
      "get",
      `employee/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async getChoose(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IEmployee[]>>>(
      "post",
      `employee/choose`,
      data,
      null,
      undefined
    );
  }

  async getProfile() {
    return await this.execute<ApiResponse<IEmployee>>(
      "get",
      `employee/profile`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `employee/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }

  async salaryList(payload: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<ISalaryEmployee[]>>>(
      "post",
      "employee/salary-list",
      payload,
      null,
      undefined
    );
  }
}
