import { DocumentsContextProvider } from "../services/documentsContext";
import Documents from "./Documents";

const index = () => {
  return (
    <DocumentsContextProvider>
      <Documents />
    </DocumentsContextProvider>
  );
};

export default index;
