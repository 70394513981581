import { UsersContextProvider } from "../services/usersContext";
import Users from "./Users";

const index = () => {
  return (
    <UsersContextProvider>
      <Users />
    </UsersContextProvider>
  );
};

export default index;
