import { CashboxesAddContextProvider } from "../services/cashboxesAddContext";
import CashboxesAdd from "./CashboxesAdd";

const index = () => {
  return (
    <CashboxesAddContextProvider>
      <CashboxesAdd />
    </CashboxesAddContextProvider>
  );
};

export default index;
