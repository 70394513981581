import { CitiesContextProvider } from "../services/citiesContext";
import Cities from "./Cities";

const index = () => {
  return (
    <CitiesContextProvider>
      <Cities />
    </CitiesContextProvider>
  );
};

export default index;
