import { CitiesAddContextProvider } from "../services/citiesAddContext";
import CitiesAdd from "./CitiesAdd";

const index = () => {
  return (
    <CitiesAddContextProvider>
      <CitiesAdd />
    </CitiesAddContextProvider>
  );
};

export default index;
