import React, { CSSProperties } from "react";
import Loading from "../loading/Loading";

interface IContainer {
  padding?: boolean;
  filter?: boolean;
  background?: boolean;
  overflow?: boolean;
  height?: boolean;
  loading?: boolean;
  children: React.ReactNode;
  style?: CSSProperties;
  border?: boolean;
  [key: string]: any;
}

const Container = ({
  children,
  padding = true,
  filter = false,
  background = true,
  overflow = true,
  height = true,
  loading = false,
  border = true,
  ...props
}: IContainer) => {
  return (
    <div
      style={{
        padding: padding ? 24 : 0,
        background: background ? "white" : "transparent",
        borderRadius: border ? 10 : 0,
        height: height ? `calc(100vh - ${filter ? "154px" : "112px"})` : "auto",
        overflow: overflow ? "scroll" : "visible",
        ...props.style,
      }}
    >
      {loading ? <Loading /> : children}
    </div>
  );
};

export default Container;
