import { Form, FormProps, message } from "antd";
import api from "common/api";
import { ITransactionType } from "common/types/transaction";
import { FieldData } from "rc-field-form/es/interface";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      const payload = {
        ...values,
      };
      const response = await api.transactionType.addTransactionType(
        payload,
        id
      );
      message.success(
        id ? "Tranzaksiya turi o'zgartirildi" : "Tranzaksiya turi yaratildi"
      );
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const getById = async (id: string) => {
    try {
      const response = await api.transactionType.getById(id);
      const fields: FieldData[] = (
        Object.keys(response.data) as (keyof ITransactionType)[]
      ).map((key) => ({
        name: key,
        value: response.data[key],
      }));
      form.setFields(fields);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.transactionType.delete(id);
        message.success("Tranzaksiya turi o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    if (id) getById(id);
  }, []);

  return {
    state: { form },
    actions: { onFinish, handleDelete },
  };
};

const TransactionTypesAddContext = createContext<any>({
  state: {},
  actions: {},
});

export const TransactionTypesAddContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <TransactionTypesAddContext.Provider value={value}>
      {children}
    </TransactionTypesAddContext.Provider>
  );
};

export default function useTransactionTypesAddContext() {
  return useContext<ReturnType<typeof Context>>(TransactionTypesAddContext);
}
