import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { useTranslation } from "react-i18next";
import api from "common/api";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";
import { IDocument } from "common/types/common";

const Context = () => {
  const [form] = Form.useForm();
  const [documents, setDocuments] = useState<IDocument[]>();
  const [loading, setLoading] = useState(true);

  const {
    state: { userId, programId },
  } = useApplicationsAddContext();

  const onFinish = async (values: any) => {
    console.log(values);
    // try {
    //   setLoadingSubmit(true);
    //   const response = await api.account.updateDocuments(values);
    //   message.success(t("account.documents.success"));
    //   setLoadingSubmit(false);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const uploadDoc = async (payload: {
    user_id: string;
    doc_id: string;
    value: string;
  }) => {
    try {
      const response = await api.application.updateUserDoc(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const getRequiredDocs = async (payload: {
    user_id: string;
    program_id: string;
  }) => {
    try {
      setLoading(true);
      const response = await api.application.getRequiredDocs(payload);
      setDocuments(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getUserDocs = async (id: string) => {
    try {
      const response = await api.application.getUserDocs(id);
      form.setFieldsValue(
        response.data.reduce((documentsAcc: any, document: IDocument) => {
          return { ...documentsAcc, [document.doc_id]: document.value };
        }, {})
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId && programId) {
      getRequiredDocs({ user_id: userId, program_id: programId });
      getUserDocs(userId);
    }
  }, []);

  return {
    state: { loading, documents, form },
    actions: { onFinish, uploadDoc },
  };
};

const ApplicationDocumentsContext = createContext<any>({
  state: {},
  actions: {},
});

export const ApplicationDocumentsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ApplicationDocumentsContext.Provider value={value}>
      {children}
    </ApplicationDocumentsContext.Provider>
  );
};

export default function useApplicationDocumentsContext() {
  return useContext<ReturnType<typeof Context>>(ApplicationDocumentsContext);
}
