import { StepProps, Typography } from "antd";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ApplicationDocuments,
  ApplicationPayment,
  ApplicationUserInfo,
} from "../components";
import { IUsers } from "common/types/users";
import api from "common/api";
import { useParams } from "react-router-dom";
import { IApplication } from "common/types/applications";

interface IStep extends StepProps {
  content: React.ReactNode;
}

const Context = () => {
  const [current, setCurrent] = useState(0);
  const [user, setUser] = useState<IUsers>();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [programId, setProgramId] = useState<string>();
  const [applicationId, setApplicationId] = useState<string>();
  const [application, setApplication] = useState<IApplication>();
  const { id } = useParams();

  const steps: IStep[] = [
    {
      title: "Shaxsiy ma’lumotlar",
      content: <ApplicationUserInfo data={user} />,
      onClick: () => {
        setCurrent((prev) => prev++);
      },
    },
    {
      title: "Kerakli hujjatlar",
      content: <ApplicationDocuments />,
      onClick: () => {
        setCurrent((prev) => prev++);
      },
    },
    {
      title: "Xizmat uchun to’lov",
      content: <ApplicationPayment />,
      onClick: () => {
        setCurrent((prev) => prev++);
      },
    },
  ];

  const getByUser = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.users.getById(id);
      setUser(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId) getByUser(userId);
  }, [userId]);

  useEffect(() => {
    if (id) setApplicationId(id);
  }, []);

  return {
    state: {
      steps,
      current,
      programId,
      loading,
      userId,
      applicationId,
      application,
    },
    actions: {
      setCurrent,
      setUserId,
      setProgramId,
      setApplicationId,
      setApplication,
    },
  };
};

const ApplicationsAddContext = createContext<any>({ state: {}, actions: {} });

export const ApplicationsAddContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ApplicationsAddContext.Provider value={value}>
      {children}
    </ApplicationsAddContext.Provider>
  );
};

export default function useApplicationsAddContext() {
  return useContext<ReturnType<typeof Context>>(ApplicationsAddContext);
}
