import { DocumentsAddContextProvider } from "../services/documentsAddContext";
import DocumentsAdd from "./DocumentsAdd";

const index = () => {
  return (
    <DocumentsAddContextProvider>
      <DocumentsAdd />
    </DocumentsAddContextProvider>
  );
};

export default index;
