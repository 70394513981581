import { ICashbox } from "common/types/cashbox";
import { Api } from "../api";
import { ApiPaging, ApiResponse, paramApi } from "common/types/common";

export class CashboxApi extends Api {
  async addCashbox(data: ICashbox, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "cashbox/update" : "cashbox/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async getAll() {
    return await this.execute<ApiResponse<ICashbox[]>>(
      "get",
      "cashbox/get-all",
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<ICashbox>>(
      "delete",
      `cashbox/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
