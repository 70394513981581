import { UsersAddContextProvider } from "../services/usersAddContext";
import UsersAdd from "./UsersAdd";

const index = () => {
  return (
    <UsersAddContextProvider>
      <UsersAdd />
    </UsersAddContextProvider>
  );
};

export default index;
