import { Form, FormProps, Select as AntSelect, SelectProps } from "antd";
import { useState } from "react";

export interface IOption {
  label: string;
  value: any;
}

interface ISelect extends SelectProps {
  label?: string;
  placeholder?: string;
  options: IOption[];
  form?: FormProps["form"];
  name?: string;
  onSelect?: (value: any) => void;
}

const Select = ({
  label,
  placeholder = "Tanlang",
  options: initialOptions,
  form,
  name,
  onSelect,
  mode,
  ...props
}: ISelect) => {
  const [options, setOptions] =
    useState<SelectProps["options"]>(initialOptions);
  const handleChange = (value: string[]) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value || undefined);
  };
  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }}>
      <AntSelect
        {...props}
        mode={mode}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};

export default Select;
