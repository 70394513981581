import { Api } from "../api";
import { ApiPaging, ApiResponse, paramApi, ICity } from "common/types/common";

export class CityApi extends Api {
  async addCity(data: any, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "city/update" : "city/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<ICity[]>>>(
      "post",
      "city/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<ICity>>(
      "get",
      `city/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<ICity>>(
      "delete",
      `city/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
