const term = {
  gender: (value: "male" | "female" | undefined) => {
    switch (value) {
      case "male":
        return "Erkak";
      case "female":
        return "Ayol";
      default:
        return "Noma'lum";
    }
  },

  marital: (value: "married" | "single" | "divorced" | undefined) => {
    switch (value) {
      case "single":
        return "Yolg'iz";
      case "married":
        return "Turmush qurgan";
      case "divorced":
        return "Ajrashgan";
      default:
        return "Mavjud emas";
    }
  },
};

export default term;
