import { TransactionTypesAddContextProvider } from "../services/transactionTypesAddContext";
import TransactionTypesAdd from "./TransactionTypesAdd";

const index = () => {
  return (
    <TransactionTypesAddContextProvider>
      <TransactionTypesAdd />
    </TransactionTypesAddContextProvider>
  );
};

export default index;
