import { message } from "antd";
import api from "common/api";
import { IPrograms } from "common/types/programs";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IPrograms>();
  const [loading, setLoading] = useState(false);

  const getById = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.programs.getById(id);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.university.delete(id);
        message.success("Universitet o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    if (id) getById(id);
  }, []);
  return {
    state: { data, loading },
    actions: { handleDelete },
  };
};

const ProgramsInfoContext = createContext<any>({ state: {}, actions: {} });

export const ProgramsInfoContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ProgramsInfoContext.Provider value={value}>
      {children}
    </ProgramsInfoContext.Provider>
  );
};

export default function useProgramsInfoContext() {
  return useContext<ReturnType<typeof Context>>(ProgramsInfoContext);
}
