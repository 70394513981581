import React from "react";

import UploadFile from "./UploadFile";
import { FormProps, UploadProps } from "antd";
import { UploadFileContextProvider } from "../services/uploadFileContext";

export interface IUploadFile extends UploadProps {
  form: FormProps["form"];
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  children?: React.ReactNode;
  afterUpload?: (name: string | undefined, data: any) => void;
}

const index = (props: IUploadFile) => {
  return (
    <UploadFileContextProvider {...props}>
      <UploadFile {...props} />
    </UploadFileContextProvider>
  );
};

export default index;
