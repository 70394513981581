import { FormProps, UploadFile, UploadProps } from "antd";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IUploadFile } from "../container";

const Context = (args: IUploadFile) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [props, setProps] = useState<{
    form: FormProps["form"];
    name: string;
  }>();

  const defaultFile = props?.form?.getFieldValue(props.name);

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];
    let file = newFileList.slice(-1)[0];

    if (file) {
      if (file?.response?.statusCode) {
        if (file?.response?.statusCode === 200) {
          props?.form?.setFieldValue(props.name, file?.response?.data);
          args?.afterUpload?.(props?.name, file?.response?.data);
        } else props?.form?.setFieldValue(props.name, undefined);
      }
      setFileList([file]);
    } else {
      setFileList([]);
    }
  };

  useEffect(() => {
    if (props?.form && props.name) {
      if (defaultFile)
        if (typeof defaultFile === "string")
          setFileList([
            {
              uid: "-1",
              name: defaultFile,
              url: process.env.REACT_APP_BASE_URL + defaultFile,
              status: "done",
            },
          ]);
    }
  }, [defaultFile]);

  return {
    state: { fileList },
    actions: {
      handleChange,
      setProps,
      setFileList,
    },
  };
};

const UploadFileContext = createContext<any>({ state: {}, actions: {} });

export const UploadFileContextProvider: FC<IUploadFile> = (args) => {
  const value = Context(args);
  return (
    <UploadFileContext.Provider value={value}>
      {args.children}
    </UploadFileContext.Provider>
  );
};

export default function useUploadFileContext() {
  return useContext<ReturnType<typeof Context>>(UploadFileContext);
}
