import { BranchesContextProvider } from "../services/branchesContext";
import Branches from "./Branches";

const index = () => {
  return (
    <BranchesContextProvider>
      <Branches />
    </BranchesContextProvider>
  );
};

export default index;
