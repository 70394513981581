import { TableProps } from "antd";
import api from "common/api";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import term from "utils/term";

interface DataType {
  key: number;
  full_name: string;
  gender: string;
  applications_count: number;
  branch: string;
  phone_number: string;
  _id: string;
}

export interface IEmployeesFilter {
  search: string;
}

const Context = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Xodim F.I.O",
        dataIndex: "full_name",
        key: "full_name",
      },
      {
        title: "Jinsi",
        dataIndex: "gender",
        key: "gender",
        render: (value) => term.gender(value),
      },
      {
        title: "Arizalar soni",
        dataIndex: "application_count",
        key: "application_count",
      },
      {
        title: "Filiali",
        dataIndex: "branch",
        key: "branch",
      },
      {
        title: "Telefon raqami",
        dataIndex: "phone_number",
        key: "phone_number",
      },
    ],
    [i18n.language]
  );

  const onRowClick = (record: DataType, rowIndex: number | undefined) => {
    navigate(`/management/employees/info/${record._id}`);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.employee.getPaging(getAllParams);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading },
    actions: { onRowClick },
  };
};

const EmployeesContext = createContext<any>({ state: {}, actions: {} });

export const EmployeesContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <EmployeesContext.Provider value={value}>
      {children}
    </EmployeesContext.Provider>
  );
};

export default function useEmployeesContext() {
  return useContext<ReturnType<typeof Context>>(EmployeesContext);
}
