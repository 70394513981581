import { Container } from "components/ui";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { IBoard } from ".";
import Lane from "../components/Lanes/container";
import useBoardContext from "../services/boardContext";
import { Task } from "../services/boardTypes";

const BoardCanvas = styled.div`
  display: block;
  height: 100%;
  overflow: hidden;
`;

const BoardOl = styled.ol`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  list-style: none;
  gap: 20px;
`;

const Board = (props: IBoard) => {
  const {
    state: { data },
    actions: { onDragEnd },
  } = useBoardContext();
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container height filter background={false} padding={false}>
        {/* <BoardCanvas className="board-canvas"> */}
        <BoardOl id="board">
          {data?.columns
            ? Object.entries(data?.columns).map(([columnId, column]) => {
                const tasks = column.taskIds
                  ? column.taskIds.map((taskId) => data.tasks?.[taskId])
                  : undefined;
                return <Lane key={column._id} column={column} tasks={tasks} />;
              })
            : "Hech narsa yo'q"}
        </BoardOl>
        {/* </BoardCanvas> */}
      </Container>
    </DragDropContext>
  );
};

export default Board;
