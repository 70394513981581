import { TableProps } from "antd";
import api from "common/api";
import { IPrograms } from "common/types/programs";
import { IField } from "components/ui/filter/container/Filter";
import dayjs from "dayjs";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DataType {
  key: number;
  university: string;
  city: string;
  type: "public" | "private";
  program_count: number;
  applications_count: number;
  _id: string;
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IPrograms[]>([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "university",
      label: "Universitet",
      name: "university",
    },
    {
      type: "city",
      label: "Shahar",
      name: "city",
    },
    {
      type: "select",
      label: "Daraja",
      name: "type",
      options: [
        "non_degree",
        "college",
        "bachelor",
        "masters",
        "phd",
        "camp",
        "short_programs",
        "joint_programs",
        "exchange_programs",
      ]?.map((degree) => ({
        label: t(`constants.degrees.${degree}`),
        value: degree,
      })),
    },
    {
      type: "select",
      label: "Til",
      name: "language",
      options: [
        {
          label: "Xitoy",
          value: "chinese",
        },
        {
          label: "Ingliz",
          value: "english",
        },
      ],
    },
    {
      type: "date",
      label: "Sana",
      name: "date",
      range: true,
    },
    {
      type: "search",
      // label: "Turi",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Dastur nomi",
        dataIndex: ["name", i18n.language],
        key: "name",
      },
      {
        title: "Universitet",
        dataIndex: ["university", "name", i18n.language],
        key: "university",
      },
      {
        title: "Shahar",
        dataIndex: ["university", "city", "name", i18n.language],
        key: "city",
      },
      {
        title: "Daraja",
        dataIndex: "type",
        key: "type",
        render: (value) => t(`constants.degrees.${value}`),
      },
      {
        title: "Til",
        dataIndex: "language",
        key: "language",
        render: (value) => t(`constants.languages.${value}`),
      },
      {
        title: "Qabul mavsumi",
        dataIndex: "admission_date",
        key: "admission_date",
        render: (value) => t(`constants.seasons.${value}`),
      },
    ],
    [i18n.language]
  );

  const onRowClick = (record: DataType, rowIndex: number | undefined) => {
    navigate(`/programs/info/${record._id}`);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.programs.getPaging(getAllParams);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading },
    actions: { onRowClick },
  };
};

const ProgramsContext = createContext<any>({ state: {}, actions: {} });

export const ProgramsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ProgramsContext.Provider value={value}>
      {children}
    </ProgramsContext.Provider>
  );
};

export default function useProgramsContext() {
  return useContext<ReturnType<typeof Context>>(ProgramsContext);
}
