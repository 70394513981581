import { ProgramsAddContextProvider } from "../services/programsAddContext";
import ProgramsAdd from "./ProgramsAdd";

const index = () => {
  return (
    <ProgramsAddContextProvider>
      <ProgramsAdd />
    </ProgramsAddContextProvider>
  );
};

export default index;
