import { EmployeesAddContextProvider } from "../services/employeesAddContext";
import EmployeesAdd from "./EmployeesAdd";

const index = () => {
  return (
    <EmployeesAddContextProvider>
      <EmployeesAdd />
    </EmployeesAddContextProvider>
  );
};

export default index;
