import { Form, FormProps, message } from "antd";
import api from "common/api";
import { ICity } from "common/types/common";
import { FieldData } from "rc-field-form/es/interface";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      const payload = {
        ...values,
      };
      const response = await api.city.addCity(payload, id);
      message.success("Shahar yaratildi");
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const getById = async (id: string) => {
    try {
      const response = await api.city.getById(id);
      const fields: FieldData[] = (
        Object.keys(response.data) as (keyof ICity)[]
      ).map((key) => ({
        name: key,
        value: response.data[key],
      }));
      form.setFields(fields);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.city.delete(id);
        message.success("Shahar o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    if (id) getById(id);
  }, []);

  return {
    state: { form },
    actions: { onFinish, handleDelete },
  };
};

const CitiesAddContext = createContext<any>({ state: {}, actions: {} });

export const CitiesAddContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <CitiesAddContext.Provider value={value}>
      {children}
    </CitiesAddContext.Provider>
  );
};

export default function useCitiesAddContext() {
  return useContext<ReturnType<typeof Context>>(CitiesAddContext);
}
