import styled from "styled-components";
import { Upload as AntUpload, Image } from "antd";
import useUploadContext from "../services/uploadContext";
import { IUpload } from ".";
import { useEffect } from "react";
import access_token from "../../../../storage/access_token";

export const StyledUpload = styled(AntUpload)`
  .ant-upload.ant-upload-select,
  .ant-upload-list-item-container {
    width: 100% !important;
    height: 200px !important;
  }

  .ant-upload-list-item .ant-upload-list-item-thumbnail img {
    object-fit: cover !important;
  }
`;

const Upload = (props: IUpload) => {
  const { form, name, children, accept } = props;

  const {
    state: { previewImage, previewOpen, fileList },
    actions: {
      handlePreview,
      handleChange,
      setPreviewOpen,
      setPreviewImage,
      setProps,
    },
  } = useUploadContext();

  useEffect(() => {
    setProps({ form, name });
  }, []);
  return (
    <>
      <StyledUpload
        action={process.env.REACT_APP_UPLOAD_URL}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={1}
        accept={accept}
        headers={{ Authorization: `Bearer ${access_token}` }}
      >
        {fileList.length >= 1 ? null : children}
      </StyledUpload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default Upload;
