import { Form, FormProps } from "antd";
import useAuth from "hooks/useAuth";
import useUser from "hooks/useUser";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { loginFields } from "./loginTypes";
import api from "common/api";

const Context = () => {
  const [form] = Form.useForm();
  const { setAuth, removeAuth } = useAuth();
  const { setUser, removeUser } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish: FormProps<loginFields>["onFinish"] = async (values) => {
    setLoading(true);
    try {
      const response = await api.common.login(values);
      setAuth(response.data.token);
      setUser(response.data);
      setLoading(false);
      navigate(`/applications/list`);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    removeAuth();
    removeUser();
  }, []);

  return {
    state: { form, loading },
    actions: { onFinish },
  };
};

const LoginContext = createContext<any>({ state: {}, actions: {} });

export const LoginContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export default function useLoginContext() {
  return useContext<ReturnType<typeof Context>>(LoginContext);
}
