import { Flex, Modal, Space, Tabs, Typography } from "antd";
import { Button, StatusText } from "components/ui";
import useApplicationsContext from "../../../services/applicationsContext";
import useInfoModalContext from "../service/infoModalContext";
import styled from "styled-components";
import { memo } from "react";
import useUser from "hooks/useUser";
import { EmployeeSelect } from "components/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const StyledTabs = styled(Tabs)`
  margin-top: 30px;
  height: 600px;
  overflow: hidden;

  .ant-tabs-tab {
    padding-left: 0px !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tabs-tab .ant-tabs-tab-btn .ant-tabs-tab-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

const InfoModal = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const {
    state: { isOpen, taskInfo: task },
    actions: { setIsOpen, setTaskInfo, getStates },
  } = useApplicationsContext();

  const {
    state: { tabItems },
    actions: { setEmployee },
  } = useInfoModalContext();

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        setTaskInfo(undefined);
        getStates();
      }}
      title={
        <Flex justify="space-between" style={{ paddingRight: 30 }}>
          <Space size="middle">
            <Typography.Title level={5} style={{ margin: 0 }}>
              {task?.first_name ?? ""} {task?.last_name ?? ""}{" "}
              {task?.family_name ?? ""}
            </Typography.Title>
            <StatusText state={task?.state} />
          </Space>
          <Space>
            {user?.is_boss && (
              <EmployeeSelect
                value={task?.employee_id}
                onSelect={setEmployee}
                placeholder="Xodim biriktirish"
              />
            )}{" "}
            {user?.is_boss === false && (
              <>
                {task?.employee_id === user._id ? (
                  <Button
                    icon={<FontAwesomeIcon icon={faCancel} />}
                    type="primary"
                    onClick={() => setEmployee("")}
                  >
                    Arizadan chiqish
                  </Button>
                ) : (
                  <Button
                    icon={<FontAwesomeIcon icon={faUser} />}
                    type="primary"
                    style={{ backgroundColor: "#1EB53A" }}
                    onClick={() => setEmployee(user._id)}
                  >
                    Arizani qabul qilish
                  </Button>
                )}
              </>
            )}
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              type="primary"
              style={{ backgroundColor: "orange" }}
              onClick={() => navigate(`/applications/create/${task?._id}`)}
            />
          </Space>
        </Flex>
      }
      width={1200}
      footer
    >
      <StyledTabs tabPosition="left" items={tabItems} />
    </Modal>
  );
};

export default memo(InfoModal);
