import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import FroalaEditor, { MyComponentProps } from "react-froala-wysiwyg";
import { FormProps } from "antd";
import { useEffect, useState } from "react";

interface IFroala extends MyComponentProps {
  name?: string | string[];
  form?: FormProps["form"];
}

const Froala = (props: IFroala) => {
  const { form, name } = props;
  const [content, setContent] = useState<string>(""); // Initial content state

  const handleEditorChange = (newContent: string) => {
    setContent(newContent);
    // Manually trigger form change event
    form?.setFieldValue(name, newContent);
  };

  useEffect(() => {
    // Set initial content from the form's initial values
    const initialContent = form?.getFieldValue(name);
    if (initialContent) {
      setContent(initialContent);
    }
  }, [form?.getFieldValue(name)]);

  return (
    <FroalaEditor
      tag="textarea"
      config={{
        placeholderText: "Matn kiriting...",
      }}
      onModelChange={handleEditorChange}
      model={content}
      {...props}
    />
  );
};

export default Froala;
