import { PaymentMethodsContextProvider } from "../services/paymentMethodsContext";
import PaymentMethods from "./PaymentMethods";

const index = () => {
  return (
    <PaymentMethodsContextProvider>
      <PaymentMethods />
    </PaymentMethodsContextProvider>
  );
};

export default index;
