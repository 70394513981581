import { NewsContextProvider } from "../services/newsContext";
import News from "./News";

const index = () => {
  return (
    <NewsContextProvider>
      <News />
    </NewsContextProvider>
  );
};

export default index;
