import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { IApplicationsFilter } from "common/types/applications";
import { Button, Filter, Loading } from "components/ui";
import { useNavigate } from "react-router-dom";

import { Board, InfoModal } from "../components";
import useApplicationsContext from "../services/applicationsContext";

const Applications = () => {
  const navigate = useNavigate();
  const {
    state: { filterFields, data, loading, states },
    actions: {},
  } = useApplicationsContext();

  return (
    <div>
      <Row>
        <Col xs={18} md={6}>
          <Button
            type="primary"
            style={{ backgroundColor: "dodgerblue" }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate("/applications/create")}
          >
            Ariza qo'shish
          </Button>
        </Col>
        <Col xs={6} md={18}>
          <Filter<IApplicationsFilter> filterFields={filterFields} />
        </Col>
      </Row>
      {loading ? <Loading /> : <Board states={states} />}
      <InfoModal />
    </div>
  );
};

export default Applications;
