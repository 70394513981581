import { Form } from "antd";
import type { Dayjs } from "dayjs";
import useQueryParams from "hooks/useQueryParams";
import { isArray } from "lodash";
import React, { FC, createContext, useContext } from "react";

const Context = () => {
  const [form] = Form.useForm();
  const { setParam } = useQueryParams();

  const handleSearch = (name: string, value: string) => {
    setParam({ name, value: value?.length ? value : undefined });
  };

  const handleSelect = (name: string, value: string[] | string | undefined) => {
    setParam({ name, value: value?.length ? value : undefined });
  };

  const handleDate = (name: string, value: Dayjs[] | Dayjs) => {
    if (isArray(value)) {
      setParam({ name: "date_from", value: value[0] ? value[0] : undefined });
      setParam({ name: "date_to", value: value[1] ? value[1] : undefined });
    } else {
      setParam({ name, value: value ? value : undefined });
    }
  };

  return {
    state: { form },
    actions: { handleSearch, handleSelect, handleDate },
  };
};

const FilterContext = createContext<any>({ state: {}, actions: {} });

export const FilterContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default function useFilterContext() {
  return useContext<ReturnType<typeof Context>>(FilterContext);
}
