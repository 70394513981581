import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { DropResult } from "react-beautiful-dnd";
import { Data } from "./boardTypes";
import { IBoard } from "../container";
import { IState } from "common/types/common";
import api from "common/api";

const Context = ({ states }: IBoard) => {
  const [data, setData] = useState<Data>();

  const onDragEnd = (result: DropResult) => {
    if (data) {
      const { destination, source, draggableId } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const start = data?.columns?.[source.droppableId];
      const finish = data?.columns?.[destination.droppableId];

      if (start && finish) {
        if (start === finish) {
          const newTaskIds = Array.from(start.taskIds ?? []);
          newTaskIds.splice(source.index, 1);
          newTaskIds.splice(destination.index, 0, draggableId);

          const newColumn = {
            ...start,
            taskIds: newTaskIds,
          };

          const newState = {
            ...data,
            columns: {
              ...data.columns,
              [newColumn._id]: newColumn,
            },
            tasks: {
              ...data.tasks,
              [draggableId]: {
                ...data.tasks[draggableId],
                position: destination.index + 1,
                state: finish,
                state_id: destination.droppableId,
              },
            },
          };

          setData(newState);
          updatePosition({
            _id: draggableId,
            state_id: destination.droppableId,
            position: destination.index + 1,
          });
          return;
        }

        const startTaskIds = Array.from(start.taskIds ?? []);
        startTaskIds.splice(source.index, 1);
        const newStart = {
          ...start,
          taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds ?? []);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
          ...finish,
          taskIds: finishTaskIds,
        };

        const newState = {
          ...data,
          columns: {
            ...data.columns,
            [newStart._id]: newStart,
            [newFinish._id]: newFinish,
          },
          tasks: {
            ...data.tasks,
            [draggableId]: {
              ...data.tasks[draggableId],
              position: destination.index + 1,
              state: finish,
              state_id: destination.droppableId,
            },
          },
        };

        setData(newState);
        updatePosition({
          _id: draggableId,
          state_id: destination.droppableId,
          position: destination.index + 1,
        });
      }
    }
  };

  const updatePosition = async (payload: {
    _id: string;
    state_id: string;
    position: number;
  }) => {
    try {
      const response = await api.application.updatePosition(payload);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const result = states?.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {} as { [key: string]: IState });

    setData({ tasks: {}, columns: result });
  }, [states]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return {
    state: { data },
    actions: { onDragEnd, setData },
  };
};

const BoardContext = createContext<any>({ state: {}, actions: {} });

export const BoardContextProvider: FC<IBoard> = ({ children, ...props }) => {
  const value = Context(props);
  return (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

export default function useBoardContext() {
  return useContext<ReturnType<typeof Context>>(BoardContext);
}
