import { UniversitiesContextProvider } from "../services/universitiesContext";
import Universities from "./Universities";

const index = () => {
  return (
    <UniversitiesContextProvider>
      <Universities />
    </UniversitiesContextProvider>
  );
};

export default index;
