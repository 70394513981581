import { ApiPaging, ApiResponse, paramApi } from "common/types/common";
import { ITransaction, ITransactionPayload } from "common/types/transaction";
import { Api } from "../api";

export class TransactionApi extends Api {
  async addTransaction(
    payload: ITransactionPayload,
    type: "income" | "expense"
  ) {
    return await this.execute<ApiResponse<any>>(
      "post",
      `transaction/${type}`,
      payload,
      null,
      undefined
    );
  }

  async salary(payload: {
    amount: number;
    employee_id: string;
    cashbox_id: string;
    payment_method_id: string;
  }) {
    return await this.execute<ApiResponse<any>>(
      "post",
      `transaction/salary`,
      payload,
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<ITransaction[]>>>(
      "post",
      "transaction/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<ITransaction[]>>(
      "get",
      `transaction/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async cancelTransaction(id: string) {
    return await this.execute<ApiResponse<ITransaction>>(
      "post",
      `transaction/cancel`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
