import {
  faBackspace,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row, Space } from "antd";
import { Button, Container } from "components/ui";
import { useParams } from "react-router-dom";
import useEmployeesAddContext from "../services/employeesAddContext";
import { Field } from "components/form";

const EmployeesAdd = () => {
  const { id } = useParams();

  const {
    state: { form },
    actions: { onFinish, handleDelete },
  } = useEmployeesAddContext();
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} md={19}>
          <Container padding={false} background={false}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Container overflow={false} height={false}>
                <Row gutter={[20, 0]}>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Filiali"
                      name="organization_id"
                      type="select-branch"
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Jinsi"
                      name="gender"
                      type="select"
                      required
                      options={[
                        {
                          label: "Erkak",
                          value: "male",
                        },
                        {
                          label: "Ayol",
                          value: "female",
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Ism Familiya Otasining ismi"
                      name="full_name"
                      type="input"
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Telefon raqami"
                      name="phone_number"
                      type="phone-number"
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Email"
                      name="email"
                      type="input"
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="KPI foiz"
                      name="kpi_percent"
                      type="number-input"
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      form={form}
                      title="Paroli"
                      name="password"
                      type="input"
                      required
                    />
                  </Col>
                </Row>
              </Container>
            </Space>
          </Container>
        </Col>
        <Col xs={24} md={5}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "#1EB53A" }}
              htmlType="submit"
            >
              Saqlash
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={id ? faTrash : faBackspace} />}
              block
              type={id ? "primary" : "default"}
              onClick={handleDelete}
            >
              {id ? "O'chirish" : "Bekor qilish"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default EmployeesAdd;
