import { Form, FormProps, Input, InputProps, Space } from "antd";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";

interface ISearchFilter {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  onChange?: (value: any) => void;
}

const StyledSpace = styled(Space)`
  display: flex;
  .ant-space-item:first-child {
    flex-grow: 1;
  }
`;

const SearchFilter = ({
  label,
  placeholder = "Qidirish...",
  form,
  name,
  onChange,
}: ISearchFilter) => {
  const handleChange: InputProps["onChange"] = (e) => {
    if (form && name)
      form?.setFieldValue(
        name,
        e.target.value === "" ? undefined : e.target.value
      );
    if (onChange) onChange(e.target.value);
  };
  return (
    <>
      <StyledSpace>
        <Form.Item name={name} style={{ margin: 0 }} label={label}>
          <Input
            style={{ minWidth: "200px" }}
            placeholder={placeholder}
            onChange={handleChange}
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
          />
        </Form.Item>
      </StyledSpace>
    </>
  );
};

export default SearchFilter;
