import { Form, FormProps, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../common/api";

interface IEmployeeSelect {
  label?: string;
  placeholder?: string;
  form?: FormProps["form"];
  name?: string;
  multiple?: boolean;
  value?: IEmployeeSelect["multiple"] extends true ? string[] : string;
  onSelect?: (
    value: IEmployeeSelect["multiple"] extends true ? string[] : string
  ) => void;
  [key: string]: unknown;
}

const EmployeeSelect = ({
  label,
  placeholder = "Xodim tanlang",
  form,
  name,
  onSelect,
  multiple = false,
  value,
  ...props
}: IEmployeeSelect) => {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (
    value: IEmployeeSelect["multiple"] extends true ? string[] : string
  ) => {
    if (form && name) form?.setFieldValue(name, value || undefined);
    if (onSelect) onSelect(value);
  };

  const universityGetAll = async () => {
    try {
      setLoading(true);
      const response = await api.employee.getChoose({ page: 1, limit: 50 });
      setOptions(
        response.data.data.map((employee) => ({
          label: employee.full_name,
          value: employee._id,
        }))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    universityGetAll();
  }, []);

  return (
    <Form.Item name={name} label={label} style={{ margin: 0 }} {...props}>
      <Select
        mode={multiple ? "multiple" : undefined}
        allowClear
        style={{ minWidth: "200px" }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        loading={loading}
        // Explicitly cast value based on multiple mode
        value={value}
      />
    </Form.Item>
  );
};

export default EmployeeSelect;
