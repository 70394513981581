import { PlusOutlined } from "@ant-design/icons";
import { faTelegram, faWeixin } from "@fortawesome/free-brands-svg-icons";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { IUsers } from "common/types/users";
import { Upload } from "components/form";
import { Container } from "components/ui";
import { t } from "i18next";
import moment from "moment";
import { useEffect } from "react";
import useUsersInfoContext, {
  FieldType,
} from "../../services/usersInfoContext";

const PersonalInfo = ({ data }: { data: IUsers | undefined }) => {
  const [form] = Form.useForm<FieldType>();

  const {
    state: { editMode, formLoading },
    actions: { onFinish, setEditMode },
  } = useUsersInfoContext();

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      birth_date: data?.birth_date && moment(data?.birth_date),
      valid_until: data?.valid_until && moment(data?.valid_until),
    });
  }, []);

  return (
    <Container height={false} style={{ height: "calc(100vh - 180px)" }}>
      <Form
        form={form}
        name="personal-info"
        onFinish={onFinish}
        layout="vertical"
        disabled={!editMode}
      >
        <Row gutter={[20, 0]}>
          <Col xs={24} md={6}>
            <Form.Item<FieldType> name="image_url" rules={[{ required: true }]}>
              <Upload form={form} name="image_url" accept="image/*">
                <button style={{ border: 0, background: "none" }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{t("users.info.image")}</div>
                </button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={18}>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={12}>
                <Form.Item<FieldType>
                  name="first_name"
                  label={t("users.info.first_name")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item<FieldType>
                  name="last_name"
                  label={t("users.info.last_name")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item<FieldType>
                  name="family_name"
                  label={t("users.info.family_name")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item<FieldType>
                  name="birth_date"
                  label={t("users.info.birth_date")}
                  rules={[{ required: true }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item<FieldType>
                  name="gender"
                  label={t("users.info.gender")}
                  layout="horizontal"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="male">{t("users.info.male")}</Radio>
                    <Radio value="female">{t("users.info.male")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="religion"
              label={t("users.info.religion")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="nation"
              label={t("users.info.nation")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="marital_status"
              label={t("users.info.marital_status")}
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  {
                    value: "single",
                    label: t("users.info.single"),
                  },
                  {
                    value: "married",
                    label: t("users.info.married"),
                  },
                  {
                    value: "divorced",
                    label: t("users.info.divorced"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="passport_number"
              label={t("users.info.passport_number")}
              rules={[
                {
                  validator: (_, value) => {
                    const pattern = /^[A-Z]{2}\d{7}$/;
                    if (pattern.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error("Passport raqami xato"));
                    }
                  },
                },
              ]}
            >
              <Input placeholder="AA0000000" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="valid_until"
              label={t("users.info.valid_until")}
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item<FieldType>
              name="social_media_link"
              label={t("users.info.social_media_link")}
              rules={[{ required: true }]}
            >
              <Input
                addonBefore={
                  <Select
                    defaultValue="https://t.me/"
                    options={[
                      {
                        value: "https://t.me/",
                        label: <FontAwesomeIcon icon={faTelegram} />,
                      },
                      {
                        value: "https://wechat.com/",
                        label: <FontAwesomeIcon icon={faWeixin} />,
                      },
                    ]}
                  />
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              name="previous_university"
              label={t("users.info.previous_university")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              name="address"
              label={t("users.info.address")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item<FieldType>
              name="lived_in_china"
              label={t("users.info.lived_in_china")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={true}>{t("users.info.yes")}</Radio>
                <Radio value={false}>{t("users.info.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={18}>
            <Form.Item<FieldType>
              name="living_purpose"
              label={t("users.info.living_purpose")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item<FieldType>
              name="currently_in_china"
              label={t("users.info.currently_in_china")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={true}>{t("users.info.yes")}</Radio>
                <Radio value={false}>{t("users.info.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={18}>
            <Form.Item<FieldType>
              name="current_university"
              label={t("users.info.current_university")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Flex justify="flex-end" gap={10}>
          {!editMode && (
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={() => setEditMode((prev) => !prev)}
              disabled={false}
              style={{ backgroundColor: "orange" }}
              //   loading={loadingSubmit}
            >
              O'zgartirish
            </Button>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<FontAwesomeIcon icon={faSave} />}
              loading={formLoading.personal_info}
            >
              {t("users.info.submit")}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Container>
  );
};

export default PersonalInfo;
