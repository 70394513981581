import { Divider, Skeleton, Typography } from "antd";
import { memo, useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { ILane } from ".";
import Card from "../../Card/container";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import useLaneContext from "../services/laneContext";

const BoardLi = styled.li`
  display: block;
  width: 272px;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
`;

const BoardSkeleton = styled(Skeleton.Input)`
  width: 272px;
  flex-shrink: 0;
  height: 100%;
  flex-grow: 1;
`;

const LaneDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LaneHeader = styled.div`
  padding-top: 20px;
`;

const LaneOl = styled.ol`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 15px;
  overflow-y: auto;
  height: 100%;
  list-style: none;
`;

const Lane = ({ column, tasks }: ILane) => {
  const {
    state: { loading, tasksLength, showLoadMore },
    actions: { setLane, onLoadMoreCards },
  } = useLaneContext();

  useEffect(() => {
    setLane(column);
  }, []);

  return (
    <BoardLi>
      {column ? (
        loading ? (
          <LaneDiv>
            <LaneHeader className="lane-header" style={{ textAlign: "center" }}>
              <Skeleton.Input
                active
                style={{ marginBottom: 5 }}
                size="small"
                block
              />
              <Skeleton.Input active style={{ marginBottom: 9 }} size="small" />
              <Skeleton.Input
                block
                active
                style={{
                  height: 3,
                  margin: 0,
                  borderRadius: 20,
                }}
              />
            </LaneHeader>
            <div style={{ height: "100%", overflow: "hidden" }}>
              <LaneOl>
                {Array.from({ length: 15 }, (_, index) => index).map(
                  (index) => (
                    <Skeleton.Input
                      active
                      block
                      style={{ height: 100, borderRadius: 12 }}
                    />
                  )
                )}
              </LaneOl>
            </div>
          </LaneDiv>
        ) : (
          <LaneDiv>
            <LaneHeader className="lane-header" style={{ textAlign: "center" }}>
              <Typography.Title
                level={5}
                style={{ marginBottom: 3, textTransform: "uppercase" }}
              >
                {column?.name}
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                {tasks?.length} ta ariza
              </Typography.Paragraph>
              <Divider
                style={{
                  height: 3,
                  backgroundColor: column.color,
                  margin: 0,
                  borderRadius: 20,
                }}
              />
            </LaneHeader>
            <div style={{ height: "100%", overflow: "hidden" }}>
              <Droppable
                droppableId={column?._id}
                isDropDisabled={
                  column.type === "new" ||
                  column.type === "first_payment" ||
                  column.type === "completed"
                }
              >
                {(provided, snapshot) => (
                  <LaneOl
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    id={`infinite-scroll-${column?._id}`}
                  >
                    <InfiniteScroll
                      dataLength={tasksLength} //This is important field to render the next data
                      next={onLoadMoreCards}
                      hasMore={showLoadMore}
                      loader={<h4>Loading...</h4>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      scrollableTarget={`infinite-scroll-${column?._id}`}
                    >
                      {tasks?.map((task, index) => (
                        <Card key={task._id} task={task} index={index} />
                      ))}
                      {provided.placeholder}
                    </InfiniteScroll>
                  </LaneOl>
                )}
              </Droppable>
            </div>
          </LaneDiv>
        )
      ) : (
        "Nothing no"
      )}
    </BoardLi>
  );
};

export default memo(Lane);
