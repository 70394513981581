import {
  Avatar,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Table,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { Button, Container } from "components/ui";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFilePen,
  faFolder,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import useUsersInfoContext from "../services/usersInfoContext";
import PersonalInfo from "../components/personalInfo/PersonalInfo";
import { Link } from "react-router-dom";
import UsersDocuments from "../components/documents/container";

const UsersInfo = () => {
  const {
    state: { user, loading },
    actions: { deleteUser },
  } = useUsersInfoContext();

  const tabItems: TabsProps["items"] = [
    {
      label: "Shaxsiy ma'lumotlar",
      icon: <FontAwesomeIcon icon={faUser} />,
      key: "personal-info",
      children: <PersonalInfo data={user} />,
    },
    // {
    //   label: "Hujjatlar",
    //   icon: <FontAwesomeIcon icon={faFolder} />,
    //   key: "documents",
    //   children: <UsersDocuments />,
    // },
    // {
    //   label: "Arizalar",
    //   icon: <FontAwesomeIcon icon={faFilePen} />,
    //   key: "applications",
    //   children: "Applications",
    // },
  ];
  return (
    <Container background={false} padding={false} loading={loading}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Row gutter={[20, 20]}>
          <Col xs={24} md={6}>
            <Container height={false}>
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%" }}
              >
                <Avatar size={75} icon={<UserOutlined />} />
                <Typography.Title level={4} style={{ textAlign: "center" }}>
                  {user?.first_name ?? ""} {user?.last_name ?? ""}{" "}
                  {user?.family_name ?? ""}
                </Typography.Title>
              </Space>
              <Divider />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>
                  <strong>Telefon raqami: </strong>
                  {user?.phone_number}
                </Typography.Text>
                <Typography.Text>
                  <strong>Email: </strong>
                  {user?.email}
                </Typography.Text>
                <Typography.Text>
                  <strong>Paroli: </strong>
                  {user?.password}
                </Typography.Text>
              </Space>
              <Space
                style={{ marginTop: 20, width: "100%" }}
                styles={{ item: { width: "100%" } }}
                wrap
              >
                <Link to={`/users/create/${user?._id}`}>
                  <Button
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    block
                    type="primary"
                    style={{ backgroundColor: "orange" }}
                  >
                    O'zgartirish
                  </Button>
                </Link>
                <Button
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  block
                  type="primary"
                  onClick={() => deleteUser(user!._id)}
                >
                  O'chirish
                </Button>
              </Space>
            </Container>
          </Col>
          <Col xs={24} md={18}>
            <Tabs
              // onChange={onChange}
              type="line"
              items={tabItems}
              // tabBarStyle={{ margin: 0 }}
            />
          </Col>
        </Row>
      </Space>
    </Container>
  );
};

export default UsersInfo;
