import { EmployeesContextProvider } from "../services/employeesContext";
import Employees from "./Employees";

const index = () => {
  return (
    <EmployeesContextProvider>
      <Employees />
    </EmployeesContextProvider>
  );
};

export default index;
