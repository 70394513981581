import { TableProps } from "antd";
import api from "common/api";
import { INews } from "common/types/news";
import { IUsers } from "common/types/users";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import moment from "moment";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DataType {
  key: number;
  university: string;
  city: string;
  type: "public" | "private";
  program_count: number;
  applications_count: number;
  _id: string;
}

export interface IUsersFilter {
  search: string;
  city: string[];
  type: string[];
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IUsers[]>([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "select",
      label: "Xolati",
      name: "status",
      options: [
        {
          label: "Ariza topshirmagan",
          value: "not_register",
        },
        {
          label: "Ariza topshirgan",
          value: "registered",
        },
      ],
    },
    {
      type: "employee",
      label: "Xodim",
      name: "employee",
    },
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "F.I.O",
        // dataIndex: "first_name",
        // key: "first_name",
        render: (data) =>
          data.first_name || data.last_name || data.family_name
            ? `${data.first_name || ""} ${data.last_name || ""} ${
                data.family_name || ""
              }`
            : "Noma'lum foydalanuvchi",
      },
      {
        title: "Telefon raqami",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        title: "E-pochta",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Ro'yhatdan o'tgan sanasi",
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => moment(created_at).format("MM/D/YYYY, h:mm:ss"),
      },
    ],
    [i18n.language]
  );

  const onRowClick = (record: DataType, rowIndex: number | undefined) => {
    navigate(`/users/info/${record._id}`);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.users.getPaging(getAllParams);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading },
    actions: { onRowClick },
  };
};

const UsersContext = createContext<any>({ state: {}, actions: {} });

export const UsersContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export default function useUsersContext() {
  return useContext<ReturnType<typeof Context>>(UsersContext);
}
