import { Col, Divider, Flex, Row, Table } from "antd";
import { IApplicationsFilter } from "common/types/applications";
import { Container, Filter, Loading } from "components/ui";
import { IField } from "components/ui/filter/container/Filter";
import useSaleFunnelContext from "../services/saleFunnelContex";
import { ResponsiveFunnel } from "@nivo/funnel";
import { useMemo } from "react";

const SaleFunnel = () => {
  const filterFields: IField[] = [
    {
      type: "date",
      label: "Sana",
      name: "date",
      range: true,
    },
  ];

  const {
    state: { statistics, loading },
  } = useSaleFunnelContext();

  const data: { id: string; value: number; label: string }[] = useMemo(
    () => [
      {
        id: "all",
        value: statistics?.total ?? 0,
        label: "Jami",
      },
      ...(statistics?.states
        ? statistics?.states?.map((stat) => ({
            id: stat.state.name,
            value: stat.count,
            label: stat.state.name,
          }))
        : []),
    ],
    [statistics]
  );

  return (
    <div>
      <Filter<IApplicationsFilter> filterFields={filterFields} />
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={[20, 20]}>
          <Col xs={12}>
            <Container height={false}>
              {statistics?.states?.map((state) => (
                <div>
                  <Flex justify="space-between">
                    <b>{state.state.name}:</b>
                    <p>{state.count} ta</p>
                  </Flex>
                  <Divider />
                </div>
              ))}
              <div>
                <Flex justify="space-between">
                  <b>Jami:</b>
                  <p>{statistics?.total} ta</p>
                </Flex>
              </div>
            </Container>
          </Col>
          <Col xs={12}>
            <div
              style={{
                height: "700px",
                borderRadius: 10,
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <ResponsiveFunnel
                data={data}
                // margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                // valueFormat=">-.4s"
                colors={{ scheme: "spectral" }}
                borderWidth={20}
                labelColor={{
                  from: "color",
                  modifiers: [["brighter", 3]],
                }}
                beforeSeparatorLength={100}
                beforeSeparatorOffset={20}
                afterSeparatorLength={100}
                afterSeparatorOffset={20}
                currentPartSizeExtension={10}
                currentBorderWidth={40}
                motionConfig="wobbly"
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SaleFunnel;
