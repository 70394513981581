import { Drawer, Form, InputNumber, Space } from "antd";
import { PaymentMethodSelect, TransactionTypeSelect } from "components/form";
import { Button } from "components/ui";
import useCashboxesContext from "../../services/cashboxesContext";

const Action = () => {
  const {
    state: { action, form },
    actions: { handleCloseAction, onFinish },
  } = useCashboxesContext();
  return (
    <Drawer
      title={action?.type === "income" ? "Kirim qilish" : "Chiqim qilish"}
      onClose={handleCloseAction}
      open={action?.open}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Space direction="vertical" style={{ width: "100%" }}>
          <TransactionTypeSelect
            form={form}
            name="transaction_type_id"
            rules={[{ required: true }]}
            extraParams={{ type: action?.type }}
            dependency={[action?.type]}
          />
          <PaymentMethodSelect
            form={form}
            name="payment_method_id"
            rules={[{ required: true }]}
          />
          <Form.Item<any>
            name="amount"
            label="Miqdor"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={action?.loading}
          >
            Saqlash
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};

export default Action;
