import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "common/api";
import useQueryParams from "hooks/useQueryParams";
import { IApplication } from "common/types/applications";
import { IState } from "common/types/common";
import useBoardContext from "../../../services/boardContext";

const Context = () => {
  const [lane, setLane] = useState<IState>();
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<IApplication[]>();

  const [tasksLength, setTasksLength] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const { getAllParams } = useQueryParams();

  const {
    actions: { setData },
  } = useBoardContext();

  const onLoadMoreCards = () => {
    setShowLoadMore(false);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.application.getPaging({
        ...getAllParams,
        page: 1,
        limit: 10,
        state_id: lane?._id,
      });

      setData((prev) => ({
        ...prev,
        columns: {
          ...prev?.columns,
          [lane!._id]: {
            ...prev!.columns![lane!._id],
            taskIds: response.data.data.map((task) => task._id),
          },
        },
        tasks: {
          ...prev?.tasks,
          ...response.data.data.reduce(
            (taskAcc, task) => ({ ...taskAcc, [task._id]: task }),
            {}
          ),
        },
      }));
      setTasks(response.data.data);
      setTasksLength(response.data.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (lane?._id) getPaging();
  }, [lane]);

  return {
    state: { loading, tasks, tasksLength, showLoadMore },
    actions: { setLane, onLoadMoreCards },
  };
};

const LaneContext = createContext<any>({ state: {}, actions: {} });

export const LaneContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return <LaneContext.Provider value={value}>{children}</LaneContext.Provider>;
};

export default function useLaneContext() {
  return useContext<ReturnType<typeof Context>>(LaneContext);
}
