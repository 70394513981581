import { NewsAddContextProvider } from "../services/newsAddContext";
import NewsAdd from "./NewsAdd";

const index = () => {
  return (
    <NewsAddContextProvider>
      <NewsAdd />
    </NewsAddContextProvider>
  );
};

export default index;
