import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Row, Space, Typography } from "antd";
import { Button, Container, FieldInfo, InfoLanguage } from "components/ui";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import useProgramsInfoContext from "../services/programsInfoContext";

const ProgramsInfo = () => {
  const [lang, setLang] = useState<"uz" | "en" | "ru">("uz");
  const {
    state: { data, loading },
    actions: { handleDelete },
  } = useProgramsInfoContext();

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={19}>
        <Container padding={false} background={false} loading={loading}>
          <Space direction="vertical" size="large">
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Ma’lumotlari</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                <Col xs={24}>
                  <FieldInfo
                    title="Dastur rasmi"
                    value={data?.image_url}
                    type="image"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Dastur nomi"
                    value={data?.name[lang]}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo title="Daraja" value={data?.type} type="text" />
                </Col>
                {/* <Col xs={12} md={8}>
                  <FieldInfo
                    title="Universitet"
                    value={data?.university.name[lang]}
                    type="text"
                  />
                </Col> */}
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Kategoriya"
                    value={data?.category}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo title="Til" value={data?.language} type="text" />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Qabul mavsumi"
                    value={moment(data?.admission_date).format("l")}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Qabulning ohirgi kuni"
                    value={moment(data?.admission_deadline).format("l")}
                    type="text"
                  />
                </Col>
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Narxlar</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Har bir yil uchun kontrakt narxi"
                    value={data?.tuition_fee}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Chegirmadagi kontrakt narxi"
                    value={data?.cost_after_discount}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Grantdan keyin o'qish to'lovi"
                    value={data?.cost_after_scholarship}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Turar joy to'lovi"
                    value={data?.accommodation_cost}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Stipendiyadan so'ng turar joy to'lovi"
                    value={data?.accommodation_cost_after_scholarship}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Universitet ro'yxatga olish to'lovi"
                    value={data?.registration_fee}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Xizmat narxi"
                    value={data?.service_cost}
                    type="text"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <FieldInfo
                    title="Ariza to’lovi"
                    value={data?.application_fee}
                    type="text"
                  />
                </Col>
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Grant ma’lumotlari</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                <Col xs={12}>
                  <FieldInfo
                    title="Grant turi"
                    value={data?.scholarship_type}
                    type="text"
                  />
                </Col>
                <Col xs={12}>
                  <FieldInfo
                    title="Grant davomiyligi yili"
                    value={data?.scholarship_duration}
                    type="text"
                  />
                </Col>
                <Col xs={24}>
                  <FieldInfo
                    title="Grant siyosati"
                    value={data?.scholarship_policy[lang]}
                    type="text"
                  />
                </Col>
              </Row>
            </Container>
            <Container overflow={false} height={false}>
              <Typography.Title level={4}>Kerakli hujjatlar</Typography.Title>
              <Divider />
              <Row gutter={[20, 20]}>
                {data?.required_documents?.map((item) => (
                  <Col xs={24}>
                    <FieldInfo value={item.name[lang]} type="text" />
                  </Col>
                ))}
              </Row>
            </Container>
          </Space>
        </Container>
      </Col>
      <Col xs={24} md={5}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <InfoLanguage onClick={(lang) => setLang(lang)} />
          <Link to={`/programs/create/${data?._id}`}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "orange" }}
            >
              O'zgartirish
            </Button>
          </Link>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            block
            type="primary"
            onClick={handleDelete}
          >
            O'chirish
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default ProgramsInfo;
