import {
  faBackspace,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Form, Row, Space, Typography } from "antd";
import { DocumentsSelect, Field, UniversitySelect } from "components/form";
import { Button, Container } from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useProgramsAddContext from "../services/programsAddContext";

const StyledFormListSpace = styled(Space)`
  .ant-space-item:nth-child(1) {
    flex-grow: 1;
  }
`;

const ProgramsAdd = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    state: { form },
    actions: { onFinish, handleDelete },
  } = useProgramsAddContext();

  console.log(form.getFieldsValue());
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} md={19}>
          <Container padding={false} background={false}>
            <Space direction="vertical" size="large">
              <Container overflow={false} height={false}>
                <Typography.Title level={4}>Ma'lumot</Typography.Title>
                <Divider />
                <Row gutter={[20, 20]}>
                  <Col xs={24}>
                    <UniversitySelect
                      form={form}
                      label="Universitet"
                      name="university_id"
                      rules={[{ required: true }]}
                    />
                  </Col>
                  <Col xs={24}>
                    <Field
                      form={form}
                      title="Dastur rasmi"
                      name="image_url"
                      type="image"
                      required
                      // language={language}
                      // style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Dastur nomi"
                      name="name"
                      type="input"
                      required
                      language={true}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Daraja"
                      name="type"
                      type="select"
                      options={[
                        "non_degree",
                        "college",
                        "bachelor",
                        "masters",
                        "phd",
                        "camp",
                        "short_programs",
                        "joint_programs",
                        "exchange_programs",
                      ]?.map((degree) => ({
                        label: t(`constants.degrees.${degree}`),
                        value: degree,
                      }))}
                      required
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Kategoriya"
                      name="category"
                      type="select"
                      options={[
                        {
                          label: "Kategoriya 1",
                          value: "category1",
                        },
                        {
                          label: "Kategoriya 1",
                          value: "category1",
                        },
                      ]}
                      required
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Til"
                      name="language"
                      type="select"
                      options={[
                        {
                          label: "Xitoy",
                          value: "chinese",
                        },
                        {
                          label: "Ingliz",
                          value: "english",
                        },
                      ]}
                      required
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Qabul mavsumi"
                      name="admission_date"
                      type="select"
                      options={[
                        {
                          label: "Qish",
                          value: "winter",
                        },
                        {
                          label: "Bahor",
                          value: "spring",
                        },
                        {
                          label: "Yoz",
                          value: "summer",
                        },
                        {
                          label: "Kuz",
                          value: "autumn",
                        },
                      ]}
                      required
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Qabulning ohirgi kuni"
                      name="admission_deadline"
                      type="date"
                      required
                    />
                  </Col>
                </Row>
              </Container>
              <Container overflow={false} height={false}>
                <Typography.Title level={4}>Narxlar</Typography.Title>
                <Divider />
                <Row gutter={[20, 20]}>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Har bir yil uchun kontrakt narxi"
                      name="tuition_fee"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Chegirmadagi kontrakt narxi"
                      name="cost_after_discount"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Grantdan keyin o'qish to'lovi"
                      name="cost_after_scholarship"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Turar joy to'lovi"
                      name="accommodation_cost"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Stipendiyadan so'ng turar joy to'lovi"
                      name="accommodation_cost_after_scholarship"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Universitet ro'yxatga olish to'lovi"
                      name="registration_fee"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Xizmat narxi"
                      name="service_cost"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      form={form}
                      title="Ariza to’lovi"
                      name="application_fee"
                      type="number-input"
                    />
                  </Col>
                </Row>
              </Container>
              <Container overflow={false} height={false}>
                <Typography.Title level={4}>
                  Grant ma’lumotlari
                </Typography.Title>
                <Divider />
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Grant turi"
                      name="scholarship_type"
                      type="select"
                      options={[
                        { label: "To'liq grant", value: "full" },
                        {
                          label: "Qisman qoplanadigan grant",
                          value: "partial",
                        },
                        { label: "Kontrakt", value: "contract" },
                      ]}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Field
                      form={form}
                      title="Grant davomiyligi yili"
                      name="scholarship_duration"
                      type="number-input"
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      form={form}
                      title="Grant siyosati"
                      name="scholarship_policy"
                      type="textarea"
                      language
                    />
                  </Col>
                </Row>
              </Container>
              <Container overflow={false} height={false}>
                <Typography.Title level={4}>Kerakli hujjatlar</Typography.Title>
                <Divider />
                <DocumentsSelect
                  form={form}
                  name="required_documents"
                  multiple
                />
              </Container>
            </Space>
          </Container>
        </Col>
        <Col xs={24} md={5}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "#1EB53A" }}
              htmlType="submit"
            >
              Saqlash
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={id ? faTrash : faBackspace} />}
              block
              type={id ? "primary" : "default"}
              onClick={handleDelete}
            >
              {id ? "O'chirish" : "Bekor qilish"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ProgramsAdd;
