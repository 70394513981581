import React, { FC, createContext, useContext } from "react";

const Context = () => {
  return {
    state: {},
    actions: {},
  };
};

const NavbarContext = createContext<any>({ state: {}, actions: {} });

export const NavbarContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  );
};

export default function useNavbarContext() {
  return useContext<ReturnType<typeof Context>>(NavbarContext);
}
