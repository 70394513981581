import React, { FC, createContext, useContext } from "react";

const Context = () => {
  return {
    state: {},
    actions: {},
  };
};

const CardContext = createContext<any>({ state: {}, actions: {} });

export const CardContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};

export default function useCardContext() {
  return useContext<ReturnType<typeof Context>>(CardContext);
}
