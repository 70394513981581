import { Api } from "../api";
import {
  ApiPaging,
  ApiResponse,
  paramApi,
  IDocument,
} from "common/types/common";

export class DocumentApi extends Api {
  async addDocument(data: IDocument, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "documents/update" : "documents/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IDocument[]>>>(
      "post",
      "documents/paging",
      data,
      null,
      undefined
    );
  }

  async getChoose(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IDocument[]>>>(
      "post",
      "documents/choose",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IDocument>>(
      "get",
      `documents/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<IDocument>>(
      "delete",
      `documents/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
