import { SalariesContextProvider } from "../services/salariesContext";
import Salaries from "./Salaries";

const index = () => {
  return (
    <SalariesContextProvider>
      <Salaries />
    </SalariesContextProvider>
  );
};

export default index;
