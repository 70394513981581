import { Input, Space, Typography } from "antd";
import moment from "moment";
import { CSSProperties } from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

interface IField {
  title?: string;
  value?: string | number | React.ReactNode;
  type: "image" | "text" | "date" | "youtube";
  style?: CSSProperties;
  html?: boolean;
  [key: string]: unknown;
}

const StyledYoutube = styled(YouTube)`
  iframe {
    width: 100%;
    height: 300px;
    border-radius: 20px;
  }
`;

const Field = ({
  title,
  value,
  type,
  style = {},
  html = false,
  ...props
}: IField) => {
  const renderValue = () => {
    switch (type) {
      case "text":
        return html ? (
          <div
            dangerouslySetInnerHTML={{
              __html: value as unknown as TrustedHTML,
            }}
          />
        ) : (
          <div
            style={{
              borderRadius: 5,
              border: "1px solid #d9d9d9",
              padding: "11px",
            }}
          >
            {value}
          </div>
        );
      case "image":
        return (
          <img
            src={`${process.env.REACT_APP_BASE_URL}${value}`}
            width="100%"
            height="200px"
            style={{ objectFit: "cover", borderRadius: 10, ...style }}
          />
        );
      case "date":
        return (
          <div
            style={{
              borderRadius: 5,
              border: "1px solid #d9d9d9",
              padding: "11px",
            }}
          >
            {moment(value as string).format("l")}
          </div>
        );
      case "youtube":
        return <StyledYoutube videoId={value} />;
      default:
        return "";
    }
  };
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {title && <Typography.Text strong>{title}:</Typography.Text>}
      {renderValue()}
    </Space>
  );
};

export default Field;
