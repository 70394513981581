import React from "react";
import { ApplicationPaymentContextProvider } from "../services/applicationPaymentContext";
import ApplicationPayment from "./ApplicationPayment";

const index = () => {
  return (
    <ApplicationPaymentContextProvider>
      <ApplicationPayment />
    </ApplicationPaymentContextProvider>
  );
};

export default index;
