import { Upload as AntUpload, Flex, Typography } from "antd";
import { useEffect } from "react";
import styled from "styled-components";
import { IUploadFile } from ".";
import access_token from "../../../../storage/access_token";
import useUploadContext from "../services/uploadFileContext";

export const StyledUpload = styled(AntUpload)`
  .ant-upload.ant-upload-select {
    width: 100%;
  }
`;

const UploadFile = (props: IUploadFile) => {
  const {
    form,
    name,
    accept = "application/pdf",
    label,
    placeholder,
    children,
    required,
  } = props;

  const {
    state: { fileList },
    actions: { handleChange, setProps },
  } = useUploadContext();

  useEffect(() => {
    setProps({ form, name });
  }, []);

  return (
    <StyledUpload
      action={process.env.REACT_APP_UPLOAD_URL}
      listType="picture"
      fileList={fileList}
      onChange={handleChange}
      maxCount={1}
      accept={accept}
      headers={{ Authorization: `Bearer ${access_token}` }}
    >
      <Flex justify="space-between" align="center">
        {label && (
          <Typography.Text>
            {required && <Typography.Text type="danger">*</Typography.Text>}{" "}
            {label}
          </Typography.Text>
        )}
        {fileList.length >= 1 ? <></> : children}
      </Flex>
      {placeholder && (
        <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
          <blockquote style={{ margin: 0 }}>{placeholder}</blockquote>
        </Typography.Paragraph>
      )}
    </StyledUpload>
  );
};

export default UploadFile;
