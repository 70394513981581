import React from "react";
import Documents from "./Documents";
import { IApplication } from "common/types/applications";
import { DocumentsContextProvider } from "../services/documentsContext";

const index = (props: { task: IApplication | undefined }) => {
  return (
    <DocumentsContextProvider {...props}>
      <Documents {...props} />
    </DocumentsContextProvider>
  );
};

export default index;
