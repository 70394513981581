import { Form, FormProps, message } from "antd";
import api from "common/api";
import { INews } from "common/types/news";
import { FieldData } from "rc-field-form/es/interface";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      const payload = {
        ...values,
      };
      const response = await api.news.addNews(payload, id);
      message.success("Yangilik yaratildi");
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const getById = async (id: string) => {
    try {
      const response = await api.news.getById(id);
      const fields: FieldData[] = (
        Object.keys(response.data) as (keyof INews)[]
      ).map((key) => ({
        name: key,
        value:
          key === "image_url"
            ? {
                uz: response.data[key].uz,
                ru: response.data[key].ru,
                en: response.data[key].en,
              }
            : response.data[key],
      }));
      form.setFields(fields);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.news.delete(id);
        message.success("Yangilik o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    if (id) getById(id);
  }, []);

  return {
    state: { form },
    actions: { onFinish, handleDelete },
  };
};

const NewsAddContext = createContext<any>({ state: {}, actions: {} });

export const NewsAddContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <NewsAddContext.Provider value={value}>{children}</NewsAddContext.Provider>
  );
};

export default function useNewsAddContext() {
  return useContext<ReturnType<typeof Context>>(NewsAddContext);
}
