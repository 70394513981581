import { NewsInfoContextProvider } from "../services/newsInfoContext";
import NewsInfo from "./NewsInfo";

const index = () => {
  return (
    <NewsInfoContextProvider>
      <NewsInfo />
    </NewsInfoContextProvider>
  );
};

export default index;
