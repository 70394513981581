import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Flex,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import { Container, Filter } from "components/ui";
import React from "react";
import useCashboxesContext, {
  ICashboxesFilter,
} from "../services/cashboxesContext";
import { Link } from "react-router-dom";
import CashboxCard from "../component/CashboxCard/CashboxCard";
import Action from "../component/Action/Action";
import { format } from "utils/format";

const Cashboxes = () => {
  const {
    state: {
      cashboxes,
      loading,
      selected,
      filterFields,
      columns,
      transactions,
    },
    actions: { selectCashbox },
  } = useCashboxesContext();
  return (
    <>
      <Flex gap={20}>
        <Container style={{ width: "350px", overflow: "hidden" }}>
          <Flex vertical style={{ height: "100%" }}>
            <Link to={`/finance/cashboxes/create`}>
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faPlus} />}
                style={{ backgroundColor: "dodgerblue", marginBottom: 15 }}
                block
              >
                Kassa qo'shish
              </Button>
            </Link>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                flexGrow: 1,
                height: "100%",
              }}
              styles={{ item: { width: "100%" } }}
            >
              {loading.cashboxes
                ? Array.from({ length: 5 }, (_, index) => index).map((i) => (
                    <Skeleton.Input
                      style={{ height: i === 0 ? 178 : 146 }}
                      block
                      active
                    />
                  ))
                : cashboxes?.map((cashbox) => (
                    <CashboxCard
                      cashbox={cashbox}
                      selected={cashbox._id === selected}
                    />
                  ))}
            </Space>
          </Flex>
        </Container>
        <Flex style={{ flexGrow: 1 }} vertical gap={20}>
          <Space direction="vertical" size="middle">
            <Space>
              {cashboxes
                ?.find((cashbox) => cashbox._id === selected)
                ?.balances?.map((balance) => (
                  <div
                    style={{
                      backgroundColor: "#00649C",
                      padding: "10px 15px",
                      borderRadius: 6,
                    }}
                  >
                    <Typography.Text
                      style={{ color: "rgba(255, 255, 255, 0.5)" }}
                    >
                      {balance.payment_method.name}
                    </Typography.Text>
                    <Typography.Title
                      level={5}
                      style={{ margin: 0, color: "white" }}
                    >
                      {format.money(balance.amount, "UZS")}
                    </Typography.Title>
                  </div>
                ))}
            </Space>
            <Flex justify="space-between">
              {/* <Button
                icon={<FontAwesomeIcon icon={faFileExport} />}
                type="primary"
                style={{ backgroundColor: "dodgerblue" }}
              >
                Eksport
              </Button> */}
              <Filter<ICashboxesFilter>
                filterFields={filterFields}
                onFinish={(values) => {
                  console.log(values);
                }}
              />
            </Flex>
          </Space>
          <Container style={{ flexGrow: 1, height: "100%" }} padding={false}>
            <Table
              columns={columns}
              dataSource={transactions}
              loading={loading.transactions}
            />
          </Container>
        </Flex>
      </Flex>
      <Action />
    </>
  );
};

export default Cashboxes;
