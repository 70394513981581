import { IUsers } from "common/types/users";
import { Api } from "../api";
import { ApiPaging, ApiResponse, paramApi } from "common/types/common";

export class UsersApi extends Api {
  async addUser(data: any, id?: string) {
    return await this.execute<ApiResponse<any>>(
      id ? "put" : "post",
      id ? "user/update" : "user/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async updatePersonalInfo(payload: IUsers) {
    return await this.execute<any>(
      "put",
      "user/update",
      payload,
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IUsers[]>>>(
      "post",
      "user/paging",
      data,
      null,
      undefined
    );
  }

  async getSetDocuments(payload: any) {
    return await this.execute<ApiResponse<any>>(
      "get",
      `user/documents`,
      payload,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IUsers>>(
      "get",
      `user/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async getDocuments(id: string) {
    return await this.execute<ApiResponse<any>>(
      "get",
      `user/documents/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `user/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
